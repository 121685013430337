import { useEffect, useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import {
  subWeeks,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  subDays,
  endOfWeek,
  startOfYear,
  subYears,
  endOfYear,
} from "date-fns";
import { useUser } from "src/features/user/UserProvider";
import {
  getLocalUserSetting,
  setLocalUserSetting,
} from "src/features/user/LocalUserSettings";

export default function SoftDateRangePicker({
  value,
  onChange,
  startLabel,
  endLabel,
  showDateLabels = false,
}) {
  const { userId: authUserId } = useUser();
  const [menu, setMenu] = useState(null);
  const [menuLabel, setMenuLabel] = useState("Last Month");
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  useEffect(() => {
    const savedDateRange = getLocalUserSetting(authUserId)?.savedDateRange;
    const showCustomDateRangePicker =
      getLocalUserSetting(authUserId)?.showCustomDateRangePicker;
    if (showCustomDateRangePicker) {
      setShowDateRangePicker(true);
    } else {
      setShowDateRangePicker(false);
    }
    const savedDateRangeLabel =
      getLocalUserSetting(authUserId)?.savedDateRangeLabel;
    if (savedDateRangeLabel) {
      setMenuLabel(savedDateRangeLabel);
    } else {
      setMenuLabel("Last 7 Days");
    }
    if (savedDateRange) {
      onChange(savedDateRange.map((date) => new Date(date)));
    } else {
      onChange([startOfDay(subWeeks(new Date(), 1)), endOfToday()]);
    }
  }, []);

  const dateSelected = (dateRange) => {
    setLocalUserSetting(authUserId, {
      savedDateRange: dateRange,
      showCustomDateRangePicker: true,
    });
    if (dateRange[0] && dateRange[1]) {
      onChange(dateRange);
    } else if (dateRange[0]) {
      onChange([dateRange[0], dateRange[0]]);
    } else if (dateRange[1]) {
      onChange([dateRange[1], dateRange[1]]);
    }
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (dateRangeSelection) => {
    switch (dateRangeSelection) {
      case "today":
        setShowDateRangePicker(false);
        onChange([startOfToday(), endOfToday()]);
        setMenuLabel("Today");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfToday(), endOfToday()],
          savedDateRangeLabel: "Today",
          showCustomDateRangePicker: false,
        });
        break;

      case "yesterday":
        setShowDateRangePicker(false);
        onChange([startOfYesterday(), endOfYesterday()]);
        setMenuLabel("Yesterday");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfYesterday(), endOfYesterday()],
          savedDateRangeLabel: "Yesterday",
          showCustomDateRangePicker: false,
        });
        break;

      case "last7":
        setShowDateRangePicker(false);
        onChange([startOfDay(subDays(new Date(), 7)), endOfToday()]);
        setMenuLabel("Last 7 days");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfDay(subDays(new Date(), 7)), endOfToday()],
          savedDateRangeLabel: "Last 7 days",
          showCustomDateRangePicker: false,
        });
        break;

      case "last30":
        setShowDateRangePicker(false);
        onChange([startOfDay(subDays(new Date(), 30)), endOfToday()]);
        setMenuLabel("Last 30 days");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfDay(subDays(new Date(), 30)), endOfToday()],
          savedDateRangeLabel: "Last 30 days",
          showCustomDateRangePicker: false,
        });
        break;

      case "thisWeek":
        setShowDateRangePicker(false);
        onChange([startOfWeek(new Date()), endOfWeek(new Date())]);
        setMenuLabel("This Week");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfWeek(new Date()), endOfWeek(new Date())],
          savedDateRangeLabel: "This Week",
          showCustomDateRangePicker: false,
        });
        break;

      case "lastWeek":
        setShowDateRangePicker(false);
        onChange([
          startOfWeek(subWeeks(new Date(), 1)),
          endOfWeek(subWeeks(new Date(), 1)),
        ]);
        setMenuLabel("Last Week");
        setLocalUserSetting(authUserId, {
          savedDateRange: [
            startOfWeek(subWeeks(new Date(), 1)),
            endOfWeek(subWeeks(new Date(), 1)),
          ],
          savedDateRangeLabel: "Last Week",
          showCustomDateRangePicker: false,
        });
        break;

      case "thisMonth":
        setShowDateRangePicker(false);
        onChange([startOfMonth(new Date()), endOfMonth(new Date())]);
        setMenuLabel("This Month");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
          savedDateRangeLabel: "This Month",
          showCustomDateRangePicker: false,
        });
        break;

      case "lastMonth":
        setShowDateRangePicker(false);
        onChange([
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ]);
        setMenuLabel("Last Month");
        setLocalUserSetting(authUserId, {
          savedDateRange: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
          savedDateRangeLabel: "Last Month",
          showCustomDateRangePicker: false,
        });
        break;

      case "thisYear":
        setShowDateRangePicker(false);
        onChange([startOfYear(new Date()), endOfYear(new Date())]);
        setMenuLabel("This Year");
        setLocalUserSetting(authUserId, {
          savedDateRange: [startOfYear(new Date()), endOfYear(new Date())],
          savedDateRangeLabel: "This Year",
          showCustomDateRangePicker: false,
        });
        break;

      case "lastYear":
        setShowDateRangePicker(false);
        onChange([
          startOfYear(subYears(new Date(), 1)),
          endOfYear(subYears(new Date(), 1)),
        ]);
        setMenuLabel("Last Year");
        setLocalUserSetting(authUserId, {
          savedDateRange: [
            startOfYear(subYears(new Date(), 1)),
            endOfYear(subYears(new Date(), 1)),
          ],
          savedDateRangeLabel: "Last Year",
          showCustomDateRangePicker: false,
        });
        break;

      case "custom":
        setShowDateRangePicker(true);
        setMenuLabel("Custom");
        setLocalUserSetting(authUserId, {
          savedDateRangeLabel: "Custom",
          showCustomDateRangePicker: true,
        });
        break;

      default:
        break;
    }
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("today")}>Today</MenuItem>
      <MenuItem onClick={() => closeMenu("yesterday")}>Yesterday</MenuItem>
      <MenuItem onClick={() => closeMenu("last7")}>Last 7 days</MenuItem>
      <MenuItem onClick={() => closeMenu("last30")}>Last 30 days</MenuItem>
      <MenuItem onClick={() => closeMenu("thisWeek")}>This Week</MenuItem>
      <MenuItem onClick={() => closeMenu("thisMonth")}>This Month</MenuItem>
      <MenuItem onClick={() => closeMenu("thisYear")}>This Year</MenuItem>
      <MenuItem onClick={() => closeMenu("lastWeek")}>Last Week</MenuItem>
      <MenuItem onClick={() => closeMenu("lastMonth")}>Last Month</MenuItem>
      <MenuItem onClick={() => closeMenu("lastYear")}>Last Year</MenuItem>
      <MenuItem onClick={() => closeMenu("custom")}>Custom</MenuItem>
    </Menu>
  );

  return (
    <SoftBox display="flex" justifyContent="flex-end" ml={2}>
      {showDateLabels && !showDateRangePicker && (
        <>
          <>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={{
                start: startLabel || "From",
                end: endLabel || "To",
              }}
            >
              <DateRangePicker
                value={value || [new Date(), new Date()]}
                onChange={dateSelected}
                disabled
                renderInput={(startProps, endProps) => (
                  <>
                    <SoftBox sx={{ mx: 2 }}>
                      <SoftTypography variant="h6"> from </SoftTypography>
                    </SoftBox>
                    <SoftInput {...startProps} />
                    <SoftBox sx={{ mx: 2 }}>
                      <SoftTypography variant="h6"> to </SoftTypography>
                    </SoftBox>
                    <SoftInput {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </>
        </>
      )}
      {showDateRangePicker && (
        <>
          <SoftBox display="flex" alignItems="center" mr={2}>
            <Icon fontSize="medium">date_range</Icon>
          </SoftBox>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={{ start: startLabel || "From", end: endLabel || "To" }}
          >
            <DateRangePicker
              value={value || [new Date(), new Date()]}
              onChange={dateSelected}
              renderInput={(startProps, endProps) => (
                <>
                  <SoftInput {...startProps} />
                  <SoftBox sx={{ mx: 2 }}>
                    <SoftTypography variant="h6"> to </SoftTypography>
                  </SoftBox>
                  <SoftInput {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </>
      )}
      <SoftBox ml={2}>
        <SoftButton variant="gradient" color="dark" onClick={openMenu}>
          {menuLabel}&nbsp;
          <Icon>expand_more</Icon>
        </SoftButton>
      </SoftBox>
      {renderMenu}
    </SoftBox>
  );
}
