import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { addDoc, doc, updateDoc, collection } from "firebase/firestore";

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// OrderDetails page components
import Header from "./components/Header";
import TransactionInfo from "./components/TransactionInfo";
import PaymentDetails from "./components/PaymentDetails";
import TrackOrder from "./components/TrackOrder";
// import BillingInformation from './components/BillingInformation';
import TransactionSummary from "./components/TransactionSummary";
import PaymentError from "./components/PaymentError";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import { formatCurrency } from "src/features/utils";
import { RefundModal } from "src/modals/RefundModal";
import { AdjustInvoiceModal } from "src/modals/AdjustInvoiceModal";
import { getBillingCollection } from "src/features/billing/collections";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as toastr from "toastr";

function TransactionDetails() {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { user } = useUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  const [openAdjustInvoice, setOpenAdjustInvoice] = useState(false);
  const handleAdjustInvoiceClose = () => {
    setOpenAdjustInvoice(false);
  };

  const [openRefundModal, setOpenRefundModal] = useState(false);
  const handleRefundModalClose = () => {
    setOpenRefundModal(false);
  };

  const transactionsDocRef = doc(
    getClubTransactionsCollection(selectedClubId),
    id
  );
  const { data: transactionData, isDataLoaded: loadedTransactionData } =
    useRealtimeDocumentData(transactionsDocRef);

  const bookingsDocRef = doc(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    transactionData?.booking?.id ?? "p"
  );
  const { data: bookingData, isDataLoaded: loadedBookingData } =
    useRealtimeDocumentData(bookingsDocRef);

  const onRefundTransaction = () => {
    setOpenRefundModal(true);
  };

  const adjustInvoice = () => {
    setOpenAdjustInvoice(true);
  };

  const onPayWithALC = (transaction) => {
    const functions = getFunctions();
    const payWithALC = httpsCallable(functions, "payWithALC");

    payWithALC({
      clubId: selectedClubId,
      transactionId: transaction.id,
    })
      .then((result) => {
        if (result.data?.success) {
          toastr.success("Payment successful", "Success");
        }
      })
      .catch((error) => {
        console.error("Error paying with ALC", error);
        toastr.error("Error paying with ALC", "Payment Error");
      });
  };

  const onSendPaymentRequest = () => {
    const functions = getFunctions();
    const resendPaymentRequest = httpsCallable(
      functions,
      "resendPaymentRequest"
    );

    resendPaymentRequest({
      clubId: selectedClubId,
      paymentRequestId: id,
    })
      .then((result) => {
        if (result.data?.success)
          toastr.success("Payment Request sent!", "Success");
      })
      .catch((error) => {
        console.error("Error completing payment request", error);
        toastr.error("Error completing payment request", "Payment Error");
      });
  };

  const onMarkAsPaid = () => {
    updateDoc(transactionsDocRef, {
      paid: true,
      payments: [
        {
          amount: transactionData.totalCost * 100,
          created: new Date().getTime(),
          status: "succeeded",
          charges: {
            data: [
              {
                updatedBy: user,
                payment_method_details: { type: "Marked As Paid" },
                amount: transactionData.totalCost * 100,
                paid: true,
              },
            ],
          },
        },
      ],
    });

    // Also add payment to the user's account balance
    const userTransactionsRef = collection(
      getBillingCollection(),
      transactionData?.pilot?.uid || transactionData?.member?.uid,
      "transactions"
    );
    addDoc(userTransactionsRef, {
      amount: transactionData.totalCost,
      date: new Date(),
      clubId: selectedClubId,
      description: `Invoice Payment`,
      type: "credit",
    });
  };

  if (
    !transactionData?.createdAt &&
    loadedTransactionData &&
    loadedBookingData &&
    transactionData?.id
  ) {
    navigate("/billing");
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>
                  Can not find this transaction...
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (
    !loadedTransactionData ||
    !transactionData ||
    ((transactionData.type === "booking" ||
      transactionData.type === "introFlight") &&
      (!loadedBookingData || !bookingData))
  ) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={9}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>Loading...</SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={9}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <Header
                    transaction={transactionData}
                    onMarkAsPaid={onMarkAsPaid}
                    onRefundTransaction={onRefundTransaction}
                    adjustInvoice={adjustInvoice}
                    onSendPaymentRequest={onSendPaymentRequest}
                    onPayWithALC={onPayWithALC}
                  />
                </SoftBox>
                <Divider />
                <SoftBox pt={1} pb={3} px={2}>
                  <SoftBox mb={3}>
                    {transactionData.type === "manual" ? (
                      <SoftBox p={3}>
                        <SoftBox width="100%" overflow="auto">
                          <Table
                            sx={{
                              minWidth: "32rem",
                            }}
                          >
                            <SoftBox component="thead">
                              <TableRow>
                                <SoftBox
                                  component="th"
                                  width={{
                                    xs: "45%",
                                    md: "50%",
                                  }}
                                  py={1.5}
                                  px={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Item
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Qty
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Rate
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Discount
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Amount
                                  </SoftTypography>
                                </SoftBox>
                              </TableRow>
                            </SoftBox>
                            <TableBody>
                              {transactionData.lineItems.map((item) => (
                                <TableRow key={item.id}>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    p={1}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {Array.isArray(item.item)
                                        ? `${item.item[0]} - ${item.item[1]}`
                                        : item.item}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {item.qty}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(item.price)}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(item.discount)}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(item.total)}
                                    </SoftTypography>
                                  </SoftBox>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </SoftBox>
                      </SoftBox>
                    ) : (
                      <TransactionInfo
                        transaction={transactionData}
                        booking={bookingData}
                      />
                    )}
                  </SoftBox>
                  {transactionData.type === "booking" && <Divider />}
                  <SoftBox mt={3}>
                    <Grid container spacing={3}>
                      {transactionData.type === "deposit" ||
                      transactionData.type === "manual" ||
                      transactionData.type === "accountFunding" ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <PaymentDetails transaction={transactionData} />
                            {/* {transactionData.payments?.[0]?.status === 'succeeded' && (
                              <SoftBox mt={3}>
                                <BillingInformation transaction={transactionData} />
                              </SoftBox>
                            )} */}
                            {transactionData.payments?.[0]?.last_payment_error
                              ?.message && (
                              <SoftBox mt={3}>
                                <PaymentError transaction={transactionData} />
                              </SoftBox>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                              ml: "auto",
                            }}
                          >
                            <TransactionSummary transaction={transactionData} />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={6} lg={3}>
                            {(transactionData.type === "booking" ||
                              transactionData.type === "introFlight") && (
                              <TrackOrder transaction={transactionData} />
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={5}>
                            {(transactionData.type === "booking" ||
                              transactionData.type === "introFlight" ||
                              transactionData.type === "manual") && (
                              <>
                                <PaymentDetails transaction={transactionData} />
                                {/* {transactionData.payments?.[0]?.status === 'succeeded' && (
                                  <SoftBox mt={3}>
                                    <BillingInformation transaction={transactionData} />
                                  </SoftBox>
                                )} */}
                                {transactionData.payments?.[0]
                                  ?.last_payment_error?.message && (
                                  <SoftBox mt={3}>
                                    <PaymentError
                                      transaction={transactionData}
                                    />
                                  </SoftBox>
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={3}
                            sx={{
                              ml: "auto",
                            }}
                          >
                            <TransactionSummary transaction={transactionData} />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
        <Modal
          open={openAdjustInvoice}
          onClose={handleAdjustInvoiceClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <AdjustInvoiceModal
            handleClose={handleAdjustInvoiceClose}
            transaction={transactionData}
          />
        </Modal>
        <Modal
          open={openRefundModal}
          onClose={handleRefundModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <RefundModal
            handleClose={handleRefundModalClose}
            transaction={transactionData}
          />
        </Modal>
      </>
    </DashboardLayout>
  );
}

export default TransactionDetails;
