import * as toastr from "toastr";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

// Setting pages components
import TableCell from "src/components/TableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubLocationsCollection } from "src/features/club/collections";
import { doc, updateDoc } from "firebase/firestore";

const getGroundTimes = () => {
  const groundTimes = [];
  let startTime = 120;
  while (startTime > 14) {
    groundTimes.push({
      value: startTime,
      label: `${startTime} mins`,
    });
    startTime -= 30;
  }
  groundTimes.push(
    { value: 15, label: "15 mins" },
    {
      value: 0,
      label: "none",
    }
  );
  return groundTimes;
};

const getTimePeriods = () => [
  {
    value: "days",
    label: "days",
  },
  {
    value: "weeks",
    label: "weeks",
  },
  {
    value: "months",
    label: "months",
  },
];

function Scheduling() {
  const { selectedLocation, selectedLocationId, selectedClubId } = useClubs();
  const { preferences } = selectedLocation;

  // const updatePreference = async (key, value) => {
  //   preferences[key] = value;
  //   try {
  //     await updateDoc(doc(getClubsCollection(), selectedClubId), {
  //       preferences,
  //     });
  //     toastr.success("Your preferences have been updated.", "Success");
  //   } catch (e) {
  //     console.error(e);
  //     toastr.error("There was an error updating your preferences.", "Error");
  //   }
  // };

  const updateLocationPreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(
        doc(getClubLocationsCollection(selectedClubId), selectedLocationId),
        {
          preferences,
        }
      );
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const toggleRequireAircraftCheckout = (event) => {
    updateLocationPreference("requireAircraftCheckout", event.target.checked);
  };

  const toggleAllowBookingNonWorkingHours = (event) => {
    updateLocationPreference(
      "allowBookingNonWorkingHours",
      event.target.checked
    );
  };

  const toggleMembersCanBookMaintenance = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      membersCanBookMaintenance: event.target.checked,
    });
  };

  const toggleMembersCanBook = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      membersCanBook: event.target.checked,
    });
  };

  const toggleStudentsCanBook = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      studentsCanBook: event.target.checked,
    });
  };

  const toggleShowMaintenanceStatus = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      showMaintenanceStatus: event.target.checked,
    });
  };

  const toggleShowMaintenanceReminders = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      showMaintenanceReminders: event.target.checked,
    });
  };

  const toggleShowOpenSquawks = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      showOpenSquawks: event.target.checked,
    });
  };

  const toggleHideMembersNames = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      hideMembersNames: event.target.checked,
    });
  };

  const toggleDisplayTailNumbers = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      hideTailNumbers: event.target.checked,
    });
  };

  const toggleAllowPublicRegistration = (event) => {
    updateLocationPreference("allowPublicRegistration", event.target.checked);
  };

  const toggleLocalFlightReview = (event) => {
    updateLocationPreference("localFlightReview", {
      ...preferences.localFlightReview,
      enabled: event.target.checked,
      time: preferences?.localFlightReview?.time
        ? preferences.localFlightReview.time
        : 90,
      timePeriod: preferences?.localFlightReview?.timePeriod
        ? preferences.localFlightReview.timePeriod
        : "days",
    });
  };

  const updateLocalFlightReviewTime = (event) => {
    updateLocationPreference("localFlightReview", {
      ...preferences.localFlightReview,
      time: Number.parseFloat(event.target.value),
    });
  };

  const updateLocalFlightReviewTimePeriod = (event) => {
    updateLocationPreference("localFlightReview", {
      ...preferences.localFlightReview,
      timePeriod: event.value,
    });
  };

  const updateCalendarSlotDuration = (event) => {
    updateLocationPreference("calendar", {
      ...preferences.calendar,
      slotDuration: Number.parseFloat(event.value),
    });
  };

  const updateMinBookingTime = (event) => {
    updateLocationPreference(
      "minBookingHours",
      Number.parseFloat(event.target.value)
    );
  };

  const updateMaxBookingTime = (event) => {
    updateLocationPreference(
      "maxBookingHours",
      Number.parseFloat(event.target.value)
    );
  };

  const updateMaxNumberOfBookings = (event) => {
    updateLocationPreference(
      "maxNumberOfBookings",
      Number.parseFloat(event.target.value)
    );
  };

  const updateMaxNumberOfWeekendBookings = (event) => {
    updateLocationPreference(
      "maxNumberOfWeekendBookings",
      Number.parseFloat(event.target.value)
    );
  };

  const updateMinPreGroundTime = (event) => {
    updateLocationPreference("minPreGround", Number.parseFloat(event.value));
  };

  const updateMinPostGroundTime = (event) => {
    updateLocationPreference("minPostGround", Number.parseFloat(event.value));
  };

  return (
    <Card id="scheduling">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Scheduling</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Configuration for the calender and bookings. Some of these settings
          can be overriden by members.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          minWidth="auto"
          sx={{
            overflow: "scroll",
          }}
        >
          <Table
            sx={{
              minWidth: "36rem",
            }}
          >
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Config Name
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]} />
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]} />
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Options
                </TableCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Hide Tail Numbers
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Turn on/off tail number display on the calendar and
                      booking modal.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.hideTailNumbers}
                    onChange={toggleDisplayTailNumbers}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Members/Students Can Schedule Maintenance
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If disabled, members who are not enrolled on a training
                      course will not be able to make reservations.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.membersCanBookMaintenance}
                    onClick={toggleMembersCanBookMaintenance}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Members Can Schedule Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If disabled, members who are not enrolled on a training
                      course will not be able to make reservations.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.membersCanBook}
                    onClick={toggleMembersCanBook}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Students Can Schedule Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If disabled, students that are enrolled in a course will
                      not be able to make reservations.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.studentsCanBook}
                    onClick={toggleStudentsCanBook}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Local Flight Review
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If enabled, members that haven&rsquo;t flown recently will
                      be required to complete local flight reviews.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.localFlightReview?.enabled}
                    onClick={toggleLocalFlightReview}
                  />
                </TableCell>
              </TableRow>

              {preferences?.localFlightReview?.enabled && (
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <SoftBox lineHeight={1.4}>
                      <SoftTypography
                        display="block"
                        variant="button"
                        fontWeight="medium"
                      >
                        Local Flight Review Requirement
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        If a pilot hasn&rsquo;t flown within this time, they
                        will be required to complete a local flight review.
                      </SoftTypography>
                    </SoftBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftBox width="55px" display="flex" flexDirection="row">
                        <SoftCurrencyInput
                          currencySymbol=""
                          placeholder="0"
                          decimalScale={0}
                          decimalPlaces={0}
                          outputFormat="number"
                          onBlur={updateLocalFlightReviewTime}
                          value={preferences?.localFlightReview?.time || 90}
                        />
                      </SoftBox>
                    </SoftBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftBox width="100px" display="flex" flexDirection="row">
                        <SoftSelect
                          options={getTimePeriods()}
                          overflow="visible"
                          onChange={updateLocalFlightReviewTimePeriod}
                          value={{
                            label:
                              preferences?.localFlightReview?.timePeriod ||
                              "days",
                            value:
                              preferences?.localFlightReview?.timePeriod ||
                              "days",
                          }}
                        />
                      </SoftBox>
                    </SoftBox>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Calendar Slot Duration
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Controls the amount of time each slot on the calendar is.
                      This is the minimum booking intervals that can be made.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftSelect
                        options={[
                          { label: "15 mins", value: 15 },
                          { label: "30 mins", value: 30 },
                          { label: "60 mins", value: 60 },
                        ]}
                        overflow="visible"
                        onChange={updateCalendarSlotDuration}
                        value={{
                          label: `${
                            preferences?.calendar?.slotDuration || 15
                          } mins`,
                          value: preferences?.calendar?.slotDuration || 15,
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Minimum Booking Times
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Sets a minimun number of hours that a booking can be for.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0"
                        decimalScale={0}
                        decimalPlaces={0}
                        outputFormat="number"
                        onBlur={updateMinBookingTime}
                        value={preferences?.minBookingHours}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Max Booking Times
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Sets a maximum number of hours that member can book an
                      aircraft for. Anything over this will be pending approval.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0"
                        decimalScale={0}
                        decimalPlaces={0}
                        outputFormat="number"
                        onBlur={updateMaxBookingTime}
                        value={preferences?.maxBookingHours}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Max Number of Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Sets a maximum number of bookings a member can have at any
                      time.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0"
                        decimalScale={0}
                        decimalPlaces={0}
                        outputFormat="number"
                        onBlur={updateMaxNumberOfBookings}
                        value={preferences?.maxNumberOfBookings}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Max Number of Weekend Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Sets a maximum number of bookings a member can have on a
                      weekend. Max booking above still applies.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftCurrencyInput
                        currencySymbol=""
                        placeholder="0"
                        decimalScale={0}
                        decimalPlaces={0}
                        outputFormat="number"
                        onBlur={updateMaxNumberOfWeekendBookings}
                        value={preferences?.maxNumberOfWeekendBookings}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Minimum Pre-Ground Time
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      When an instructor is scheduled, the minimum amount of
                      time booked for ground school.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftSelect
                        options={getGroundTimes()}
                        overflow="visible"
                        onChange={updateMinPreGroundTime}
                        value={{
                          label: preferences?.minPreGround,
                          value: preferences?.minPreGround,
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Minimum Post-Ground Time
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      When an instructor is scheduled, the minimum amount of
                      time booked for ground school.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox width="100px" display="flex" flexDirection="row">
                      <SoftSelect
                        options={getGroundTimes()}
                        overflow="visible"
                        onChange={updateMinPostGroundTime}
                        value={{
                          label: preferences?.minPostGround,
                          value: preferences?.minPostGround,
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Show Maintenance Status on Calendar
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Show the maintenance status of the aircraft within the
                      booking on the calendar.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.showMaintenanceStatus}
                    onClick={toggleShowMaintenanceStatus}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Display Maintenance Reminders On Booking
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Choose weather to show upcoming maintenance reminders on
                      the booking, check out and check in pages.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.showMaintenanceReminders}
                    onClick={toggleShowMaintenanceReminders}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Display Open Squawks On Booking
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Choose weather to show open squawks on the booking, check
                      out and check in pages.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.showOpenSquawks}
                    onClick={toggleShowOpenSquawks}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Hide Members Names On Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Simply displays &rsquo;reserved&rsquo; instead of the
                      members name on bookings. Staff members still see the full
                      name.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.calendar?.hideMembersNames}
                    onClick={toggleHideMembersNames}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Allow public user registration
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Allows members of public to register with your club/school
                      using the public registration link.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.allowPublicRegistration}
                    onClick={toggleAllowPublicRegistration}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Require pilots to be checked out on Aircraft
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If a member is not checked out on an aircraft, they will
                      not be able to book it or dispatched the aircraft.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.requireAircraftCheckout}
                    onClick={toggleRequireAircraftCheckout}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                    >
                      Allow booking during Pilots non working hours
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      If booking happens within no working hours of a pilot, the
                      booking will appear as pending.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]} />
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.allowBookingNonWorkingHours}
                    onClick={toggleAllowBookingNonWorkingHours}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Scheduling;
