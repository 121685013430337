import { useState } from "react";
import * as toastr from "toastr";
// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubLocationsCollection } from "src/features/club/collections";

import { doc, updateDoc } from "firebase/firestore";
import Switch from "@mui/material/Switch";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftSelect from "src/components/SoftSelect";

function FuelReimbursement() {
  const { selectedLocation, selectedClubId, selectedLocationId } = useClubs();
  const { preferences } = selectedLocation;
  const [reimbursementType, setReimbursementType] = useState(
    preferences?.fuelReimbursement?.type || {}
  );

  const updateLocationPreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(
        doc(getClubLocationsCollection(selectedClubId), selectedLocationId),
        {
          preferences,
        }
      );
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const toggleFuelReimbursement = (event) => {
    updateLocationPreference("fuelReimbursement", {
      ...preferences.fuelReimbursement,
      enabled: event.target.checked,
      amount: preferences?.fuelReimbursement?.amount ?? 0,
    });
  };

  const toggleForceFuelReceipt = (event) => {
    updateLocationPreference("fuelReimbursement", {
      ...preferences.fuelReimbursement,
      forceReceipt: event.target.checked,
    });
  };

  const onChangeFuelReimbursementType = (event) => {
    setReimbursementType(event);
    updateLocationPreference("fuelReimbursement", {
      ...preferences.fuelReimbursement,
      type: event,
    });
  };

  const handleFuelReimbursementRateChange = (event) => {
    let rate = Number(event.target.value);
    if (!rate || Number.isNaN(rate)) rate = 0;

    updateLocationPreference("fuelReimbursement", {
      ...preferences.fuelReimbursement,
      rate,
    });
  };

  const handleMaxFuelReimbursementChange = (event) => {
    let rate = Number(event.target.value);
    if (!rate || Number.isNaN(rate)) rate = 0;

    updateLocationPreference("fuelReimbursement", {
      ...preferences.fuelReimbursement,
      maxAmount: rate,
    });
  };

  return (
    <Card
      id="fuel-reimbursement"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Fuel Reimbursement</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Settings for fuel reimbursement when renting aircraft wet
        </SoftTypography>
      </SoftBox>

      <SoftBox p={3} pb={1} lineHeight={1}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <SoftBox lineHeight={1.4}>
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
            >
              Enable Fuel Reimbursement
            </SoftTypography>
            <SoftTypography variant="caption" color="text" fontWeight="regular">
              If enabled, pilots can add fuel reimbursements when checking in
              aircraft
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={2} mr={1}>
            <Switch
              checked={preferences?.fuelReimbursement?.enabled ?? false}
              onChange={toggleFuelReimbursement}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {preferences?.fuelReimbursement?.enabled && (
        <>
          <SoftBox p={3} pb={1} lineHeight={1}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <SoftBox lineHeight={1.4}>
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Force Receipt Upload
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                >
                  If enabled, a recipt must be uploaded when adding a fuel
                  reimbursement
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={2} mr={1}>
                <Switch
                  checked={
                    preferences?.fuelReimbursement?.forceReceipt ?? false
                  }
                  onChange={toggleForceFuelReceipt}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox p={3} pb={1} lineHeight={1}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <SoftBox lineHeight={1.4}>
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Reimbursement Type
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                >
                  Set how the reimbursement amount is collected at time of
                  aircraft check in
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={2} mr={1} width={300}>
                <SoftSelect
                  options={[
                    {
                      label: "Gallons",
                      value: "gallons",
                    },
                    {
                      label: "Dollars",
                      value: "dollar",
                    },
                  ]}
                  value={reimbursementType}
                  onChange={(e) => onChangeFuelReimbursementType(e)}
                  label="Reimbursement Type"
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>

          {reimbursementType.value === "dollar" ? (
            <SoftBox pl={3} pr={3} pb={3} lineHeight={1}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                lineHeight={1}
              >
                <SoftBox lineHeight={1.4}>
                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    Max Allowed Amount
                  </SoftTypography>
                  <SoftTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular"
                  >
                    Enter a maximum amount that can be reimbursed per booking.
                    Enter $0 for no limit on reimbursement.
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  lineHeight={1}
                >
                  <SoftBox ml={1} width={80}>
                    <SoftCurrencyInput
                      currencySymbol="$"
                      placeholder="0"
                      decimalPlaces={2}
                      outputFormat="number"
                      onBlur={handleMaxFuelReimbursementChange}
                      value={preferences?.fuelReimbursement?.maxAmount ?? 0}
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          ) : (
            <SoftBox pl={3} pr={3} pb={3} lineHeight={1}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                lineHeight={1}
              >
                <SoftBox lineHeight={1.4}>
                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    Reimbursement Amount
                  </SoftTypography>
                  <SoftTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular"
                  >
                    Enter an amount to be reimbursed per gallon of fuel
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  lineHeight={1}
                >
                  <SoftBox ml={1} width={80}>
                    <SoftCurrencyInput
                      currencySymbol="$"
                      placeholder="0"
                      decimalPlaces={2}
                      outputFormat="number"
                      onBlur={handleFuelReimbursementRateChange}
                      value={preferences?.fuelReimbursement?.rate ?? 0}
                    />
                  </SoftBox>
                  <SoftBox ml={1} mr={1}>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      per gallon
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          )}
        </>
      )}
    </Card>
  );
}

export default FuelReimbursement;
