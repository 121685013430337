import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";

import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import UserType from "src/features/user-permissions/UserType";
import SoftBadge from "src/components/SoftBadge";
import SoftTypography from "src/components/SoftTypography";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getUserEnrolledCoursesCollection } from "src/features/user/collections";

// const { borderRadius } = borders;
const { grey } = colors;

const statusColors = {
  NOT_STARTED: "secondary",
  IN_PROGRESS: "warning",
  PASS: "success",
  FAIL: "error",
};

function Row({ course, onDeleteClick }) {
  const { userPermissions } = useUserPermissions();
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(
            `/lms/students/${course.member?.value}/courses/${course.enrollmentId}/${course.id}`
          );
        }}
      >
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="light">
            {course.title}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="light">
            {course.instructor?.label}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="light">
            {course.currentLesson || ""}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="light">
            {course?.completionDate?.toDate().toDateString()}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftBadge
            variant="contained"
            color={statusColors[course.status] || "secondary"}
            size="xs"
            badgeContent={course?.status.replace("_", " ") || "No status"}
            container
            sx={{
              marginRight: 1,
              textTransform: "capitalize",
            }}
          />
        </TableCell>
        {(userPermissions.userType === UserType.ADMIN ||
          userPermissions.userType === UserType.SUPERADMIN) && (
          <TableCell>
            <IconButton
              aria-label="delete"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClick(course.id);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </TableCell>
        )}
        <TableCell align="right" sx={{ maxWidth: "30px", p: 0, pr: 1 }}>
          <IconButton
            aria-label="go"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/lms/students/${course.member?.value}/courses/${course.enrollmentId}/${course.id}`
              );
            }}
          >
            <ChevronRight />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
Row.propTypes = {
  course: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func,
};

const CourseTable = ({ enrolledCourses, refreshEnrollmentData }) => {
  const { userPermissions } = useUserPermissions();
  const { deleteData } = entityCrudUtils();

  const handleDeleteClick = (enrolledCourseId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover any progress or grades for this course.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const enrolledCourse = enrolledCourses.find(
            (course) => course.id === enrolledCourseId
          );
          if (!enrolledCourse) {
            console.error("Error deleting enrolled course ", enrolledCourseId);
            return;
          }
          deleteData({
            entity: getUserEnrolledCoursesCollection(
              enrolledCourse.member?.value,
              enrolledCourse.enrollmentId
            ),
            pathSegmentsArr: [enrolledCourseId],
          });
          refreshEnrollmentData();
        }
      });
  };

  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        borderRadius: "0",
        mb: 4,
        ml: 2,
        backgroundColor: grey[100],
      }}
    >
      <Table
        stickyHeader
        style={{
          borderSpacing: "0",
          maxWidth: "calc(100% - 4rem)",
        }}
        size="small"
      >
        <TableHead>
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
              },
            }}
          >
            <HeaderCell>
              <HeaderCellText color="text">Title</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText color="text">Instructor</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText color="text">Current Lesson</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText color="text">Completion Date</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText color="text">Status</HeaderCellText>
            </HeaderCell>
            {(userPermissions.userType === UserType.ADMIN ||
              userPermissions.userType === UserType.SUPERADMIN) && (
              <HeaderCell>
                <HeaderCellText color="text">Actions</HeaderCellText>
              </HeaderCell>
            )}
            <HeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {enrolledCourses?.length ? (
            enrolledCourses.map((course) => (
              <Row
                course={course}
                key={course.id}
                onDeleteClick={handleDeleteClick}
              />
            ))
          ) : (
            <TableRow>
              <TableCell sx={{ color: grey[500] }}>
                <i>No Courses to show.</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CourseTable.propTypes = {
  enrolledCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  refreshEnrollmentData: PropTypes.func,
};

export default CourseTable;
