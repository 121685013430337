import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import parse from "html-react-parser";

function AnnouncementInfo({ announcement }) {
  const html = parse(announcement.message, {
    replace: (domNode) => {
      if (domNode.name === "img") {
        domNode.attribs = {
          ...domNode.attribs,
          style: "max-width:100%; height:auto; border-radius:8px;", // Inline styles
        };
      }
      return domNode;
    },
  });

  return (
    <SoftBox px={0}>
      {announcement && announcement.createdAt && (
        <>
          <SoftBox px={3} mb={1}>
            <SoftTypography variant="h3" color="primary" fontWeight="bold">
              {announcement.title}
            </SoftTypography>
          </SoftBox>
          <SoftBox px={3} mb={2}>
            <SoftTypography variant="h5" color="secondary" fontWeight="medium">
              {format(announcement.createdAt.toDate(), "MMMM dd, yyyy")}
            </SoftTypography>
          </SoftBox>
          {/* <SoftBox mt={3} mb={1} ml={0.5}>
            <SoftTypography variant="caption" fontWeight="bold">
              Message:
            </SoftTypography>
          </SoftBox> */}
          <SoftBox px={3} m={0} mb={2}>
            <SoftTypography variant="body2" color="text" verticalAlign="middle">
              {html}
            </SoftTypography>
          </SoftBox>
        </>
      )}
    </SoftBox>
  );
}

AnnouncementInfo.propTypes = {
  announcement: PropTypes.object.isRequired,
};

export default AnnouncementInfo;
