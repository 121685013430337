import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import Footer from "src/components/Footer";
import Grid from "@mui/material/Grid";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import CurrencyFormat from "react-currency-format";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getClubLocationsCollection } from "src/features/club/collections";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";
import { calculateFees, calculateAchFees } from "src/features/utils";
import { ArrowBack } from "@mui/icons-material";
import logo from "src/assets/images/logo-inline.svg";
import * as toastr from "toastr";

function RenderInput({
  name,
  label,
  errors,
  control,
  fullWidth = false,
  type = "text",
  disabled = false,
}) {
  return (
    <Grid item xs={12} md={fullWidth ? 12 : 6}>
      <SoftBox mb={0.1} lineHeight={0}>
        <SoftBox mb={0.5} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ref: inputRef } }) => (
            <SoftInput
              onChange={onChange}
              inputRef={inputRef}
              value={value}
              disabled={disabled}
              type={type}
            />
          )}
        />
        {errors?.[name]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[name].message}
          </SoftTypography>
        )}
      </SoftBox>
    </Grid>
  );
}

function InvoicePaymentDetails({
  stripe,
  elements,
  clubTransaction,
  paymentIntent,
  paymentMethod,
}) {
  const { clubId } = useParams();
  const navigate = useNavigate();
  const [preTaxCost, setPreTaxCost] = useState(0);
  const [taxCost, setTaxCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: location } = useRealtimeDocumentData(
    doc(
      getClubLocationsCollection(clubTransaction?.club?.id),
      clubTransaction?.location?.id
    )
  );

  useEffect(() => {
    if (clubTransaction) {
      const priceBeforeTax = clubTransaction?.totalCost || 0;
      setPreTaxCost(priceBeforeTax);

      if (location?.preferences) {
        const taxRate = clubTransaction?.taxRate
          ? clubTransaction.taxRate / 100
          : 0;
        const taxFees = priceBeforeTax * taxRate;

        let fees;
        if (paymentMethod === "ach") {
          fees = calculateAchFees(
            priceBeforeTax + taxFees,
            location.preferences.clubPaysAchFees
          );
        } else {
          fees = calculateFees(
            priceBeforeTax + taxFees,
            undefined,
            undefined,
            clubTransaction?.location?.clubPaysFees ??
              location.preferences.clubPaysFees,
            true,
            "pm_"
          );
        }
        setTaxCost(fees.chargeToPilot - priceBeforeTax);
        setTotalCost(fees.chargeToPilot);
      }
    }
  }, [location]);

  const schema = yup
    .object({
      password: yup.string().when("createAccount", {
        is: true,
        then: yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .required("A password is required."),
      }),
    })
    .required();

  const { handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (!stripe || !elements) {
      return;
    }

    const stripeResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/pay/${clubId}/${clubTransaction?.id}/payment`,
      },
      redirect: "if_required",
    });

    if (stripeResult.error) {
      console.error("Stripe Payment Error:", stripeResult.error.message);
      toastr.error(stripeResult.error.message, "Payment Error");
      setIsSubmitting(false);
    } else {
      const functions = getFunctions();
      const completePaymentRequest = httpsCallable(
        functions,
        "completePaymentRequest"
      );

      completePaymentRequest({
        clubId: clubTransaction?.club?.id,
        locationId: clubTransaction?.location?.id,
        userId: clubTransaction?.pilot?.uid || clubTransaction?.member?.uid,
        paymentRequestId: clubTransaction?.id,
        paymentIntent,
      })
        .then((result) => {
          navigate(
            `/pay/${clubTransaction?.club.id}/${result.data.clubBillingEntryId}/confirmation`,
            {
              replace: true,
            }
          );
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.error("Error completing payment request", error);
          toastr.error("Error completing payment request", "Payment Error");
          setIsSubmitting(false);
        });
    }
  };

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="form"
      role="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SoftButton
        onClick={() => navigate(-1)}
        sx={{ left: 0, top: 1, position: "absolute" }}
        color="dark"
        variant="button"
      >
        <ArrowBack fontSize="medium" lineHeight={2} marginRight={2} />

        <SoftTypography variant="h6" fontWeight="light">
          &nbsp;&nbsp;&nbsp;Back
        </SoftTypography>
      </SoftButton>
      <Grid container spacing={3} width={{ sm: 1, md: 0.8 }} pt={5}>
        <Grid item md={12}>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            pb={3}
          >
            <SoftBox component="img" src={logo} width={250} />
          </SoftBox>
          <SoftTypography variant="h3" p={2} alignSelf="flex-start">
            Checkout/Payment
          </SoftTypography>
        </Grid>
        <Grid item md={6} xs={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mt={2} mb={2}>
              <SoftTypography mb={1} variant="h5">
                Your Order
              </SoftTypography>
              <hr />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="medium">
                Cost Summary
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Flight Cost:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body2" fontWeight="medium">
                  <CurrencyFormat
                    value={Math.round((preTaxCost || 0) * 100) / 100 || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            {taxCost > 0 && (
              <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                <SoftBox>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    // color={transaction.clubPaysFees ? 'error' : 'text'}
                  >
                    Taxes & Fees:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography
                    variant="body2"
                    fontWeight="medium"
                    // color={transaction.clubPaysFees ? 'error' : 'text'}
                  >
                    <CurrencyFormat
                      value={Math.round(taxCost * 100) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={Math.round(totalCost * 100) / 100}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item md={6} xs={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mx={4} my={2}>
              <PaymentElement
                options={{
                  defaultValues: {
                    billingDetails: {
                      email: clubTransaction?.pilot?.email,
                      name: `${clubTransaction?.pilot?.firstName} ${clubTransaction?.pilot?.lastName}`,
                    },
                  },
                }}
              />
              <SoftBox display="flex" flexDirection="row-reverse" mt={4}>
                <SoftButton
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                >
                  Proceed with payment
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <SoftBox display="flex" justifyContent="center">
        <SoftBox width={450}>
          <Footer />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default function InjectedInvoicePayment({ ...rest }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <InvoicePaymentDetails stripe={stripe} elements={elements} {...rest} />
      )}
    </ElementsConsumer>
  );
}

RenderInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

InvoicePaymentDetails.propTypes = {
  stripe: PropTypes.object,
  elements: PropTypes.object,
  paymentRequest: PropTypes.object,
  clubTransaction: PropTypes.object,
  paymentIntent: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};
