import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as toastr from "toastr";
// import { useClubs } from "src/features/club/ClubProvider";
// import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
// import { getClubCustomRatesCollection } from "src/features/club/collections";

const schema = yup
  .object({
    code: yup.string().required("Provide a verification code."),
  })
  .required();

export function VerifyAccountModal({ handleClose, paymentIntent, userId }) {
  // const { selectedClubId } = useClubs();
  // const { addData, updateData } = entityCrudUtils();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const functions = getFunctions();
    const verifyMicroDeposits = httpsCallable(functions, "verifyMicroDeposits");

    verifyMicroDeposits({
      userId,
      paymentIntentId: paymentIntent.id,
      code: data.code,
    })
      .then((result) => {
        console.log("result", result);
        // if (result?.data) {
        //   setPendingPayments(
        //     result.data.data.filter(
        //       (payment) =>
        //         payment.status !== "requires_payment_method" &&
        //         payment.status !== "succeeded"
        //     )
        //   );
        // } else {
        //   setPendingPayments([]);
        // }
      })
      .catch((error) => {
        console.error("error", error);
        toastr.error("Error", error.message);
      });
    // if (rate)
    //   await updateData(
    //     {
    //       entity: getClubCustomRatesCollection(selectedClubId),
    //       pathSegmentsArr: [rate.id],
    //     },
    //     data
    //   );
    // else {
    //   await addData(getClubCustomRatesCollection(selectedClubId), data);
    // }

    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Verify ACH Account
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Verificaition Code
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="SMXXXX"
                  {...register("code", {
                    required: true,
                  })}
                />
                {errors?.code?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.code.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Verify
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

VerifyAccountModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  paymentIntent: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};
