import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { formatCurrency, toTitleCase } from "src/features/utils";
import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where, doc, getDoc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getAircraftCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { openModal$ } from "src/modals/modalConfiguration";

const columns = [
  {
    Header: "date",
    accessor: "completedAt",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PPp")} /> : null,
  },
  {
    Header: "Pilot",
    accessor: "extendedProps.pilot.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Pilot 2",
    accessor: "extendedProps.pilot2.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "extendedProps.instructor.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Aircraft",
    accessor: "extendedProps.aircraft.tailNumber",
    Cell: ({ value, row: { original } }) => {
      return (
        <DefaultCell
          value={`${value ? `N${value}` : ""} - ${
            original?.extendedProps?.aircraft?.displayName || ""
          }`}
        />
      );
    },
  },
  {
    Header: "Type",
    accessor: "extendedProps.type",
    Cell: ({ value }) => (
      <DefaultCell value={toTitleCase(value.label || value.value || "---")} />
    ),
  },
  {
    Header: "Hobbs Out",
    width: 75,
    accessor: "flightData.startHobbs",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? Math.round(value * 10) / 10 : "-"}`} />
    ),
  },
  {
    Header: "Hobbs In",
    width: 75,
    accessor: "flightData.endHobbs",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? Math.round(value * 10) / 10 : "-"}`} />
    ),
  },
  {
    Header: "Tach Out",
    width: 75,
    accessor: "flightData.startTach1",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? Math.round(value * 10) / 10 : "-"}`} />
    ),
  },
  {
    Header: "Tach In",
    width: 75,
    accessor: "flightData.endTach1",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? Math.round(value * 10) / 10 : "-"}`} />
    ),
  },
  {
    Header: "Flight Time",
    width: 75,
    accessor: "flightData.totalHobbsHours",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? Math.round(value * 10) / 10 : "-"}`} />
    ),
  },
  {
    Header: "Instruction Time",
    width: 75,
    accessor: "flightData",
    Cell: ({ value }) => (
      <DefaultCell
        value={`${
          value?.instructionHoursGround || value?.instructionHours
            ? Math.round(
                Math.max(
                  (value.instructionHoursGround || 0) +
                    (value?.instructionHours || 0),
                  0
                ) * 10
              ) / 10
            : "-"
        }`}
      />
    ),
  },
];

function CheckInsReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    clubUsers,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("completedAt", ">=", dateRange[0]),
    orderBy("completedAt", "desc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const addBookingData = async (bookings) => {
    const filteredBookings = bookings.filter(
      (booking) =>
        booking?.completedAt &&
        isWithinInterval(booking.completedAt.toDate(), {
          start: dateRange[0],
          end: dateRange[1],
        })
    );
    const rowData = await Promise.all(
      filteredBookings.map(async (booking) => {
        const pilot =
          booking?.extendedProps?.pilot?.value?.length > 0
            ? clubUsers.get(booking?.extendedProps?.pilot?.value)
            : null;
        const pilot2 =
          booking?.extendedProps?.pilot2?.value?.length > 0
            ? clubUsers.get(booking?.extendedProps?.pilot2?.value)
            : null;
        const instructor =
          booking?.extendedProps?.instructor?.value?.length > 0
            ? clubUsers.get(booking?.extendedProps?.instructor?.value)
            : null;
        let aircraft =
          booking?.extendedProps?.aircraft?.value?.length > 0
            ? await getDoc(
                doc(
                  getAircraftCollection(selectedClubId),
                  booking?.extendedProps?.aircraft?.value
                )
              )
            : null;
        if (!aircraft?.data()) {
          aircraft =
            booking?.extendedProps?.aircraft?.value?.length > 0
              ? await getDoc(
                  doc(
                    getSimulatorsCollection(selectedClubId),
                    booking?.extendedProps?.aircraft?.value
                  )
                )
              : null;
        }
        const fullBookingData = {
          ...booking,
          extendedProps: {
            ...booking?.extendedProps,
            pilot,
            pilot2,
            instructor,
            aircraft: aircraft?.data(),
          },
        };
        return fullBookingData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      Date: format(row.completedAt?.toDate(), "MM/dd/yyyy HH:mm"),
      Pilot: row?.extendedProps?.pilot?.displayName || "---",
      "Pilot 2": row?.extendedProps?.pilot2?.displayName || "---",
      Instructor: row?.extendedProps?.instructor?.displayName || "---",
      "Aircraft Tail":
        (row?.extendedProps?.aircraft?.aircraft_description?.tail_number ??
          row?.extendedProps?.aircraft?.tailNumber) ||
        "---",
      "Aircraft Name": row?.extendedProps?.aircraft?.displayName || "---",
      "Aircraft Rate": row?.extendedProps?.aircraft?.hourlyRate
        ? formatCurrency(row?.extendedProps?.aircraft?.hourlyRate)
        : "---",
      "Hobbs Out": `${
        row?.flightData?.startHobbs
          ? Math.round(row.flightData.startHobbs * 10) / 10
          : "-"
      }`,
      "Hobbs In": `${
        row?.flightData?.endHobbs
          ? Math.round(row.flightData.endHobbs * 10) / 10
          : "-"
      }`,
      "Tach1 Out": `${
        row?.flightData?.startTach1
          ? Math.round(row.flightData.startTach1 * 10) / 10
          : "-"
      }`,
      "Tach1 In": `${
        row?.flightData?.endTach1
          ? Math.round(row.flightData.endTach1 * 10) / 10
          : "-"
      }`,
      "Flight Time": `${
        row?.flightData?.totalHobbsHours
          ? Math.round(row.flightData.totalHobbsHours * 10) / 10
          : "-"
      }`,
      "Instruction Time": `${
        row?.flightData?.instructionHoursGround ||
        row?.flightData?.instructionHours
          ? Math.round(
              Math.max(
                (row.flightData.instructionHoursGround || 0) +
                  (row?.flightData?.instructionHours || 0),
                0
              ) * 10
            ) / 10
          : "-"
      }`,
    }));

    setCsvData(csvDataFromRows);
    setRows(rowData);
  };

  useEffect(() => {
    if (!bookingsLoaded || !allBookings) return;
    addBookingData(allBookings);
  }, [allBookings, bookingsLoaded, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-check_ins-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            onRowSelect={(booking) => {
              openModal$.next({
                modalName: "BOOKING_INFO_MODAL",
                modalProps: { bookingId: booking.id },
              });
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CheckInsReport;
