// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
// import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";

import { format, isBefore } from "date-fns";

// Billing page components
import InvoiceItem from "src/pages/club-billing/account-details/components/InvoiceItem";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { where, query } from "firebase/firestore";
import { useEffect, useState } from "react";

function Invoices({ userId, accountId }) {
  const { selectedClubId } = useClubs();
  const [transactions, setTransactions] = useState([]);

  const clubsTransactionsCollectionRef = query(
    getClubTransactionsCollection(selectedClubId),
    where("pilot.uid", "==", userId)
  );
  const { data: allTransactions } = useRealtimeCollectionData(
    clubsTransactionsCollectionRef,
    true
  );

  const clubsTransactions2CollectionRef = query(
    getClubTransactionsCollection(selectedClubId),
    where("member.uid", "==", userId)
  );
  const { data: allTransactions2 } = useRealtimeCollectionData(
    clubsTransactions2CollectionRef,
    true
  );

  useEffect(() => {
    if (accountId) {
      setTransactions(
        allTransactions
          .concat(allTransactions2)
          .sort((a, b) =>
            isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
          )
          .filter(
            (transaction) =>
              transaction.accountId === accountId &&
              transaction.type !== "paymentRequest" &&
              transaction.type !== "deposit"
          )
      );
    } else {
      setTransactions(
        allTransactions
          .concat(allTransactions2)
          .sort((a, b) =>
            isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
          )
          .filter(
            (transaction) =>
              !transaction.accountId &&
              transaction.type !== "paymentRequest" &&
              transaction.type !== "deposit"
          )
      );
    }
  }, [allTransactions, allTransactions2]);

  // const handleViewAllInvoices = () => {
  //   // navigate('/account/billing');
  // };

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Invoices
        </SoftTypography>
        {/* <SoftButton
          variant="outlined"
          color="info"
          size="small"
          onClick={handleViewAllInvoices}
        >
          view all
        </SoftButton> */}
      </SoftBox>
      <SoftBox p={2}>
        {transactions.length === 0 && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>There are no invoices yet..</i>
            </SoftTypography>
          </SoftBox>
        )}
        {transactions.length > 0 && (
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {transactions.map((invoice) => (
              <InvoiceItem
                key={invoice.id}
                date={`${format(invoice.createdAt.toDate(), "MMM dd yyyy")}`}
                id={invoice.id}
                data={invoice}
                totalAmount={invoice.totalCost}
                invoice={invoice}
              />
            ))}
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

export default Invoices;
