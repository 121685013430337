let sessionTokenFactory: (() => Promise<string>) | null;
const getToken = async (): Promise<string> => {
  if (!sessionTokenFactory)
    throw new Error("Forgot to register session factory!");
  return sessionTokenFactory();
};

interface ICall {
  method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
  url: string;
  params?: { [key: string]: any };
  auth?: boolean;
}

const BASE_URL = import.meta.env.VITE_API_URL;
const callBuilder = async <T>({ method, url, params, auth = true }: ICall) => {
  const controller = new AbortController();
  if (method === "GET" && params) {
    url += new URLSearchParams(params).toString();
  }
  const fbToken = await getToken();
  const fullUrl = `${BASE_URL}/${url}`;
  const options = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(auth && !!fbToken ? { Authorization: `Bearer ${fbToken}` } : {}),
    },
    signal: controller.signal,
    ...(params && method !== "GET" && { body: JSON.stringify(params) }),
  };

  const currentFetch = async () => {
    return fetch(fullUrl, options).then(
      (res) =>
        (res.ok ? (res.json() as Promise<T>) : Promise.reject(res)).catch(
          (err) => Promise.reject(err)
        ) // TODO: Add error handling
    );
  };

  return {
    call: currentFetch,
    cancel: () => controller.abort.bind(controller),
  };
};

export const ApiWrapper = {
  get: async <T>({ ...data }: Omit<ICall, "method">) =>
    await callBuilder<T>({ method: "GET", ...data }),
  post: async <T>({ ...data }: Omit<ICall, "method">) =>
    await callBuilder<T>({ method: "POST", ...data }),
  delete: async <T>({ ...data }: Omit<ICall, "method">) =>
    await callBuilder<T>({ method: "DELETE", ...data }),
  put: async <T>({ ...data }: Omit<ICall, "method">) =>
    await callBuilder<T>({ method: "PUT", ...data }),
  registerSessionTokenFactory: (fn: () => Promise<string>) => {
    sessionTokenFactory = fn;
  },
};
