import { forwardRef, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import SoftSelect from "src/components/SoftSelect";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useUser } from "src/features/user/UserProvider";
import { usePermissions } from "src/hooks/usePermissions";
import { useClubs } from "src/features/club/ClubProvider";

import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getClubAccountBalancesWithId,
  getUserBalanceWithId,
} from "src/features/user/utils";
import { formatCurrency } from "src/features/utils";
import { systemPermissions } from "src/interfaces/roles/role.interface";

const SoftSelectPaymentMethod = forwardRef(
  ({ forUser, onChange, enableNonRev, ...rest }, ref) => {
    const { selectedClubId, selectedLocation, clubAccounts, clubUsers } =
      useClubs();
    const { user } = useUser();
    if (!forUser && user) {
      forUser = user;
    } else {
      forUser = clubUsers?.get(forUser?.id || forUser?.uid);
    }
    const { hasAccess } = usePermissions();

    const [paymentCards, setPaymentCards] = useState([]);
    const [accountBalances, setAccountBalances] = useState();
    const [standardAccountBalance, setStandardAccountBalance] = useState(0);
    const [terminalReaders, setTerminalReaders] = useState([]);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
    const [loadingCards, setLoadingCards] = useState(true);
    const [loadingTerminals, setLoadingTerminals] = useState(true);

    const onSelect = (event) => {
      setSelectedPaymentMethod(event);
    };

    useEffect(() => {
      onChange(selectedPaymentMethod);
    }, [selectedPaymentMethod]);

    const fetchAccountBalances = async () => {
      const clubAccountBalancesDoc = await getClubAccountBalancesWithId(
        forUser?.id || forUser?.uid,
        selectedClubId
      );

      console.log("Club Account Balances", clubAccountBalancesDoc?.data());

      setAccountBalances(clubAccountBalancesDoc?.data());
    };

    const fetchStandardAccountBalance = async () => {
      const clubAccountBalancesDoc = await getUserBalanceWithId(
        forUser?.id || forUser?.uid
      );

      setStandardAccountBalance(
        clubAccountBalancesDoc?.data()?.[selectedClubId] || 0
      );
    };

    const fetchCards = async () => {
      const functions = getFunctions();
      const getCustomerCards = httpsCallable(functions, "getCustomerCards");

      getCustomerCards({
        customerId: forUser?.stripeId,
      }).then((paymentMethods) => {
        const cards = [];
        paymentMethods.data.forEach((paymentMethod) => {
          if (paymentMethod.type === "card") {
            cards.push(paymentMethod);
          }
        });
        setPaymentCards(cards);
        setLoadingCards(false);
      });
    };

    const fetchTerminalReaders = async () => {
      const functions = getFunctions();
      const getStripeLocationTerminals = httpsCallable(
        functions,
        "getStripeLocationTerminals"
      );
      getStripeLocationTerminals({
        stripeLocationId: selectedLocation?.stripeLocationId,
      }).then((terminalsResult) => {
        const activeTerminalReaders = [];
        if (terminalsResult.data) {
          terminalsResult.data.data.forEach((terminal) => {
            if (terminal.status === "online") {
              activeTerminalReaders.push(terminal);
            }
          });
          setTerminalReaders(activeTerminalReaders);
        }
        setLoadingTerminals(false);
      });
    };

    useEffect(() => {
      fetchAccountBalances();
      fetchStandardAccountBalance();
      fetchCards();
      if (selectedLocation?.stripeLocationId) fetchTerminalReaders();
      else setLoadingTerminals(false);
    }, []);

    useEffect(() => {
      const availablePaymentOptions = [];

      if (selectedLocation.preferences?.canPayOnAccount) {
        availablePaymentOptions.push({
          value: undefined,
          label: `Pay On Account (Balance: ${formatCurrency(
            standardAccountBalance || 0
          )})`,
        });
        if (accountBalances) {
          Object.keys(accountBalances).forEach((accountId) => {
            const accountBalance = accountBalances[accountId];
            const accountDetails = clubAccounts.find(
              (account) => account.id === accountId
            );

            if (
              (accountBalance > 0 || accountDetails?.allowNegativeBalance) &&
              accountDetails
            ) {
              availablePaymentOptions.push({
                value: accountId,
                label: `${accountDetails?.name} (Balance: ${formatCurrency(
                  accountBalance
                )})`,
              });
            }
          });
        }

        // availablePaymentOptions.push({
        //   label: "Pay later",
        //   value: "payOnAccount",
        // });
      }

      if (
        paymentCards &&
        paymentCards.length > 0 &&
        !selectedLocation.preferences?.disableCardPayments
      ) {
        paymentCards.map((paymentMethod) =>
          availablePaymentOptions.push({
            value: paymentMethod.id,
            label: `${paymentMethod.card.brand.toUpperCase()}:  xxxx xxxx xxxx ${
              paymentMethod.card.last4
            }`,
          })
        );
      }

      terminalReaders.map((terminal) =>
        availablePaymentOptions.push({
          value: terminal.id,
          label: `Card Reader: ${terminal.label}`,
        })
      );

      setPaymentMethodOptions(availablePaymentOptions);

      if (enableNonRev && hasAccess([systemPermissions.NON_REV_BOOKING])) {
        availablePaymentOptions.push({
          label: "Non Revenue",
          value: "nonRev",
        });
      }
    }, [paymentCards, terminalReaders, accountBalances]);

    // useEffect(() => {
    //   if (forUser?.defaultCard) {
    //     console.debug("Setting default card", forUser?.defaultCard);
    //     setSelectedPaymentMethod({
    //       value: forUser.defaultCard,
    //       label: "Default Card",
    //     });
    //   }
    // }, []);

    useEffect(() => {
      if (
        paymentMethodOptions.length === 1 ||
        (enableNonRev && paymentMethodOptions.length === 2)
      ) {
        onSelect({
          value: "",
          label: `Pay On Account (Balance: ${formatCurrency(
            standardAccountBalance || 0
          )})`,
        });
      }
    }, [paymentMethodOptions]);

    if (paymentMethodOptions.length === 0)
      return (
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="button" fontWeight="light">
            {(loadingCards || loadingTerminals) && <i>Loading...</i>}
            {!loadingCards && !loadingTerminals && (
              <i>No Payment Options Available</i>
            )}
          </SoftTypography>
        </SoftBox>
      );

    return (
      <SoftSelect
        {...rest}
        ref={ref}
        value={selectedPaymentMethod}
        options={paymentMethodOptions}
        onChange={onSelect}
      />
    );
  }
);

SoftSelectPaymentMethod.displayName = "SoftSelectPaymentMethod";

// Typechecking props for the SoftSelect
SoftSelectPaymentMethod.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  onChange: PropTypes.func,
  forUser: PropTypes.object,
  enableNonRev: PropTypes.bool,
};

export default SoftSelectPaymentMethod;
