import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Divider, Checkbox, Grid, Modal } from "@mui/material";

// SoftBox Components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftSelect from "src/components/SoftSelect";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubInstructorPreferencesCollection } from "src/features/club/collections";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { EditWorkHoursModal } from "src/modals/EditWorkHoursModal";
import { getFunctions, httpsCallable } from "firebase/functions";
import UserType from "src/features/user-permissions/UserType";

const instructorTypes = [
  { label: "Chief Flight Instructor", value: "ccfi" },
  { label: "Asst. Chief Instructor", value: "acfi" },
  { label: "Check Instructor", value: "ci" },
  { label: "Instructor", value: "" },
];

function InstructorPreferences({ instructor, handleClose }) {
  const { selectedClubId, selectedClub, selectedLocation, selectedLocationId } =
    useClubs();
  const [hourlyRate, setHourlyRate] = useState(0);
  const [hourlyRateGround, setHourlyRateGround] = useState(0);
  const [payrollRate, setPayrollRate] = useState(0);
  const [payrollRateNonRev, setPayrollRateNonRev] = useState(0);
  const [instructorType, setInstructorType] = useState("");
  const { updateData } = entityCrudUtils();
  const [useClubDefaultRate, setUseClubDefaultRate] = useState(false);
  const [useClubDefaultRateGround, setUseClubDefaultRateGround] =
    useState(false);
  const [openEditWorkHours, setOpenEditWorkHours] = useState(false);
  const [instructorPrefs, loadingInstructorPrefs] = useDocument(
    doc(
      getClubInstructorPreferencesCollection(
        selectedClubId,
        selectedLocationId
      ),
      instructor.id || "noid"
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const showEditWorkHours = () => {
    setOpenEditWorkHours(true);
  };

  const closeEditWorkHours = () => {
    setOpenEditWorkHours(false);
  };

  const updateInstructorPrefs = async (updates) =>
    updateData(
      {
        entity: getClubInstructorPreferencesCollection(
          selectedClubId,
          selectedLocationId
        ),
        pathSegmentsArr: [instructor.id],
      },
      updates
    );

  useMemo(() => {
    if (loadingInstructorPrefs || handleClose) return;
    if (instructorPrefs && instructorPrefs.data()) {
      setHourlyRate(instructorPrefs.data().hourlyRate || 0);
      setHourlyRateGround(instructorPrefs.data().hourlyRateGround || 0);
      setPayrollRate(instructorPrefs.data().payrollRate || 0);
      setPayrollRateNonRev(instructorPrefs.data().payrollRateNonRev || 0);
      setInstructorType({
        label: instructorTypes.find(
          (el) => el.value === instructorPrefs.data().instructorType || ""
        )?.label,
        value: instructorPrefs.data().instructorType || "",
      });
      setUseClubDefaultRate(instructorPrefs.data().useClubDefaultRate || false);
      setUseClubDefaultRateGround(
        instructorPrefs.data().useClubDefaultRateGround || false
      );
    }
  }, [instructorPrefs]);

  const handleUseClubDefaultRate = () => {
    updateInstructorPrefs({
      useClubDefaultRate: !useClubDefaultRate,
    });
    // setUseClubDefaultRate(!useClubDefaultRate);
  };

  const handleUseClubDefaultRateGround = () => {
    updateInstructorPrefs({
      useClubDefaultRateGround: !useClubDefaultRateGround,
    });
    // setUseClubDefaultRateGround(!useClubDefaultRateGround);
  };

  useMemo(() => {
    if (!handleClose && useClubDefaultRate) {
      setHourlyRate(
        selectedLocation?.preferences?.defaultInstructorHourlyRate || 0
      );
      updateInstructorPrefs({
        hourlyRate:
          selectedLocation?.preferences?.defaultInstructorHourlyRate || 0,
        useClubDefaultRate,
      });
    }
  }, [useClubDefaultRate]);

  useMemo(() => {
    if (!handleClose && useClubDefaultRateGround) {
      setHourlyRateGround(
        selectedLocation?.preferences?.defaultInstructorHourlyRate || 0
      );
      updateInstructorPrefs({
        hourlyRateGround:
          selectedLocation?.preferences?.defaultInstructorHourlyRate || 0,
        useClubDefaultRateGround,
      });
    }
  }, [useClubDefaultRateGround]);

  const onChangeHourlyRate = async (e) => {
    if (
      !handleClose &&
      instructorPrefs &&
      e.target.value !== instructorPrefs.data()?.hourlyRate
    ) {
      await updateInstructorPrefs({ hourlyRate: e.target.value });
    } else {
      setHourlyRate(e.target.value);
    }
  };

  const onChangeHourlyRateGround = async (e) => {
    if (
      !handleClose &&
      instructorPrefs &&
      e.target.value !== instructorPrefs.data()?.hourlyRateGround
    ) {
      await updateInstructorPrefs({ hourlyRateGround: e.target.value });
    } else {
      setHourlyRateGround(e.target.value);
    }
  };

  const onChangePayrollRate = async (e) => {
    if (
      !handleClose &&
      instructorPrefs &&
      e.target.value !== instructorPrefs.data()?.payrollRate
    ) {
      await updateInstructorPrefs({ payrollRate: e.target.value });
    } else {
      setPayrollRate(e.target.value);
    }
  };

  const onChangePayrollRateNonRev = async (e) => {
    if (
      !handleClose &&
      instructorPrefs &&
      e.target.value !== instructorPrefs.data()?.payrollRateNonRev
    ) {
      await updateInstructorPrefs({ payrollRateNonRev: e.target.value });
    } else {
      setPayrollRateNonRev(e.target.value);
    }
  };

  const onChangeInstructorType = async (e) => {
    if (
      !handleClose &&
      instructorPrefs &&
      e.value !== instructorPrefs.data()?.instructorType
    ) {
      await updateInstructorPrefs({ instructorType: e.value });
    } else {
      setInstructorType(e);
    }
  };

  const onSubmit = async () => {
    const preferences = {
      instructorType: instructorType.value,
      hourlyRate,
      payrollRate,
      useClubDefaultRate,
      deleted: false,
    };

    if (instructor.id) {
      await updateInstructorPrefs(preferences);
      // const existingPermissions = await getDoc(
      //   doc(getUserPermissionsCollection(instructor.id), selectedLocationId)
      // );

      // TODO: Replace this by adding an instructor role
      // if (
      //   existingPermissions.exists() &&
      //   existingPermissions.data()?.userType !== UserType.SUPERADMIN &&
      //   existingPermissions.data()?.userType !== UserType.ADMIN
      // ) {
      //   await updateData(
      //     {
      //       entity: getUserPermissionsCollection(instructor.id),
      //       pathSegmentsArr: [selectedLocationId],
      //     },
      //     {
      //       clubId: selectedClubId,
      //       locationId: selectedLocationId,
      //       addedAt: new Date(),
      //       userType: UserType.INSTRUCTOR,
      //     }
      //   );
      // }
    } else {
      const permissions = {};
      permissions[selectedLocationId] = UserType.INSTRUCTOR;

      const invite = {
        clubId: selectedClubId,
        locationId: selectedLocationId,
        clubName: selectedClub.name,
        email: instructor.email,
        firstName: instructor.firstName,
        lastName: instructor.lastName,
        permissions,
        preferences,
      };
      console.debug("Invite:", invite);
      // await addData(getInvitesCollection(), invite);
      const functions = getFunctions();
      const addUser = httpsCallable(functions, "addUser");
      addUser(invite).then((addUserResult) => {
        console.debug("Add User Result:", addUserResult);
        handleClose();
      });
    }

    handleClose();
  };

  return (
    <SoftBox pt={1}>
      {!loadingInstructorPrefs && (
        <>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            height="100%"
          >
            <SoftBox p={0}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Instructor Type
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftSelect
                    options={instructorTypes}
                    value={instructorType}
                    onChange={onChangeInstructorType}
                  />
                </SoftBox>
              </SoftBox>

              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Hourly Rate (Flight)
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox pr={2} width={100}>
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0"
                        decimalPlaces={2}
                        decimalPlacesShownOnBlur={2}
                        decimalPlacesShownOnFocus={2}
                        outputFormat="number"
                        value={hourlyRate}
                        onBlur={onChangeHourlyRate}
                        disabled={useClubDefaultRate}
                      />
                    </SoftBox>
                    <SoftBox ml={0.5} display="inline-block" width={105}>
                      <Checkbox
                        checked={useClubDefaultRate}
                        onClick={handleUseClubDefaultRate}
                      />
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={handleUseClubDefaultRate}
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        Use Default
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Hourly Rate (Ground)
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox pr={2} width={100}>
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0"
                        decimalPlaces={2}
                        decimalPlacesShownOnBlur={2}
                        decimalPlacesShownOnFocus={2}
                        outputFormat="number"
                        value={hourlyRateGround}
                        onBlur={onChangeHourlyRateGround}
                        disabled={useClubDefaultRateGround}
                      />
                    </SoftBox>
                    <SoftBox ml={0.5} display="inline-block" width={105}>
                      <Checkbox
                        checked={useClubDefaultRateGround}
                        onClick={handleUseClubDefaultRateGround}
                      />
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={handleUseClubDefaultRateGround}
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        Use Default
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Payroll Rate (Billable)
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox width={90}>
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0"
                        decimalPlaces={2}
                        decimalPlacesShownOnBlur={2}
                        decimalPlacesShownOnFocus={2}
                        outputFormat="number"
                        value={payrollRate}
                        onBlur={onChangePayrollRate}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Payroll Rate (Clerical)
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox width={90}>
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0"
                        decimalPlaces={2}
                        decimalPlacesShownOnBlur={2}
                        decimalPlacesShownOnFocus={2}
                        outputFormat="number"
                        value={payrollRateNonRev}
                        onBlur={onChangePayrollRateNonRev}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              {!handleClose && (
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Work Hours
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <SoftButton
                      variant="outlined"
                      color="dark"
                      size="small"
                      onClick={showEditWorkHours}
                    >
                      edit
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>

          {handleClose && (
            <Grid
              container
              columns={{ xs: 12 }}
              display="flex"
              justifyContent="flex-end"
            >
              <Grid item xs={12} marginY={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <SoftButton color="secondary" onClick={handleClose}>
                  Cancel
                </SoftButton>
                <SoftButton
                  color="primary"
                  sx={{
                    marginLeft: 2,
                  }}
                  onClick={onSubmit}
                >
                  Send Invite
                </SoftButton>
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Modal
        open={openEditWorkHours}
        onClose={closeEditWorkHours}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <EditWorkHoursModal
          instructor={instructor}
          handleClose={closeEditWorkHours}
        />
      </Modal>
    </SoftBox>
  );
}

InstructorPreferences.propTypes = {
  instructor: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

export default InstructorPreferences;
