import { useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import ModalContainer from "src/components/ModalContainer";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { ArrowBack } from "@mui/icons-material";
import SoftButton from "src/components/SoftButton";
import SoftInput from "src/components/SoftInput";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import SoftSelect from "src/components/SoftSelect";
import * as toastr from "toastr";

import { updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export function CancelBookingModal({ handleClose, bookingRef, booking }) {
  const { selectedLocation } = useClubs();
  const { userId } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cancellationReasons = [];

  selectedLocation.preferences?.cancellationReasons?.forEach((element) => {
    cancellationReasons.push({
      label: element.title,
      value: element.title,
      ...element,
    });
  });

  const [reason, setReason] = useState("");
  const [newComment, setNewComment] = useState("");

  const handleReasonChange = (event) => {
    setReason(event);
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const cancelBooking = () => {
    if (reason.requireDetails && !newComment) {
      toastr.error("Please provide a reason for the cancellation.");
      return;
    }
    setIsSubmitting(true);
    updateDoc(bookingRef, {
      cancelled: true,
      cancelledAt: new Date(),
      cancelledBy: userId,
      cancellationReason: { title: reason.value, details: newComment },
    });
    if (reason.cost && reason.cost > 0) {
      const functions = getFunctions();
      const chargeCancellationFee = httpsCallable(
        functions,
        "chargeCancellationFee"
      );
      chargeCancellationFee({
        bookingId: booking.id,
        clubId: booking.extendedProps.clubId,
        locationId: booking.extendedProps.locationId,
        cancellationReason: reason,
      }).then(() => {
        setIsSubmitting(false);
        handleClose(true);
      });
    } else {
      setIsSubmitting(false);
      handleClose(true);
    }
  };

  const renderBackButton = () => (
    <SoftButton
      onClick={() => handleClose()}
      sx={{ left: 0, bottom: -12, position: "absolute" }}
      color="light"
      variant="text"
    >
      <SoftBox mr={1} lineHeight={1}>
        <ArrowBack fontSize="medium" />
      </SoftBox>

      <SoftTypography variant="h6" fontWeight="light">
        Go Back
      </SoftTypography>
    </SoftButton>
  );

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            {renderBackButton()}
            <SoftTypography variant="h5" fontWeight="bold">
              Cancel Booking
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Cancellation Reason
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <SoftSelect
                    options={cancellationReasons}
                    value={reason}
                    onChange={handleReasonChange}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Further Details
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <SoftInput
                    value={newComment}
                    placeholder="Write your comment"
                    multiline
                    rows={5}
                    onChange={handleCommentChange}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={4}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton
              disabled={isSubmitting || !reason}
              type="submit"
              onClick={() => cancelBooking()}
              color="error"
            >
              Cancel Booking
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

CancelBookingModal.propTypes = {
  handleClose: PropTypes.func,
  bookingRef: PropTypes.any,
  booking: PropTypes.object,
};
