import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

// Images
import masterCardLogo from "src/assets/images/logos/mastercard.png";
import visaLogo from "src/assets/images/logos/visa.png";

import { doc, updateDoc } from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { getFunctions, httpsCallable } from "firebase/functions";

import AddNewCard from "src/modals/AddNewCard";
import { useClubs } from "src/features/club/ClubProvider";

function PaymentMethods({ user }) {
  const { selectedClubId } = useClubs();
  const { borderWidth, borderColor } = borders;
  const { light } = colors;
  const [defaultCard, setUserDefaultCard] = useState(user?.defaultCard);

  const [cards, setCards] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchCards = () => {
    console.log("fetching cards", user.stripeId);
    const functions = getFunctions();
    const getCustomerCards = httpsCallable(functions, "getCustomerCards");
    getCustomerCards({
      customerId: user.stripeId,
    })
      .then((paymentMethods) => {
        const paymentCards = [];
        paymentMethods.data.forEach((paymentMethod) => {
          if (paymentMethod.type === "card") {
            paymentCards.push(paymentMethod);
          }
        });
        setCards(paymentCards);
      })
      .catch((error) => {
        console.error("Error fetching cards", error);
      });
  };

  const deleteCard = (cardId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          const functions = getFunctions();
          const deleteCardFromCustomer = httpsCallable(
            functions,
            "deleteCardFromCustomer"
          );
          deleteCardFromCustomer({
            paymentMethodId: cardId,
          }).then(() => {
            setCards(cards.filter((card) => card.id !== cardId));
          });
        }
      });
  };

  const setDefaultCard = async (cardId) => {
    await updateDoc(doc(getUsersCollection(), user?.uid), {
      defaultCard: cardId,
    });
    setUserDefaultCard(cardId);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = (result) => {
    if (result.success) fetchCards();
    setOpen(false);
  };

  useMemo(() => {
    console.log("user", user);
    if (user?.stripeId) {
      fetchCards();
    } else {
      setCards([]);
    }
    if (user?.defaultCard) {
      setUserDefaultCard(user.defaultCard);
    } else {
      setUserDefaultCard("");
    }
  }, [user]);

  return (
    <Card
      sx={{
        borderRadius: borders.borderRadius.sm,
        border: 1,
        borderColor: light.main,
        boxShadow: "none",
      }}
    >
      <SoftBox border={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
          bgColor="light"
        >
          <SoftTypography variant="h6" fontWeight="medium">
            Payment Methods
          </SoftTypography>
          <SoftButton
            variant="gradient"
            color="dark"
            disabled={selectedClubId === "7wnF5gazVPmnkMGiDUO0"}
            onClick={handleOpen}
          >
            <Icon
              sx={{
                fontWeight: "bold",
              }}
            >
              add
            </Icon>
            &nbsp;add new card
          </SoftButton>
        </SoftBox>
      </SoftBox>

      <SoftBox
        xs={12}
        padding={1}
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="column">
          {cards.length > 0 ? (
            cards.map((card) => (
              <Grid item xs={12} xl={12} key={card.id}>
                <SoftBox
                  border={`${borderWidth[1]} solid ${borderColor}`}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                >
                  <SoftBox
                    component="img"
                    src={
                      card?.card?.brand === "visa" ? visaLogo : masterCardLogo
                    }
                    maxWidth="50px"
                    alt="master card"
                    width="10%"
                    mr={2}
                  />
                  <SoftBox display="flex" flexDirection="column" mr={2}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                      {card.card.last4}
                    </SoftTypography>
                    <SoftTypography variant="caption" fontWeight="medium">
                      Expires: {card.card.exp_month}/{card.card.exp_year}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="row" mr={2}>
                    {card.id !== defaultCard && (
                      <SoftBox ml={1} lineHeight={0}>
                        <Tooltip
                          title="Delete Card"
                          placement="top"
                          onClick={() => deleteCard(card.id)}
                        >
                          <Icon
                            sx={{
                              cursor: "pointer",
                            }}
                            fontSize="small"
                          >
                            delete
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    )}
                    <SoftBox lineHeight={0} ml={1}>
                      {card.id === defaultCard ? (
                        <Tooltip title="Default Card" placement="top">
                          <Icon fontSize="small" color="info">
                            star
                          </Icon>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Set As Default"
                          placement="top"
                          onClick={() => setDefaultCard(card.id)}
                        >
                          <Icon
                            sx={{
                              cursor: "pointer",
                            }}
                            fontSize="small"
                          >
                            star_outline
                          </Icon>
                        </Tooltip>
                      )}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            ))
          ) : (
            <SoftBox m={2}>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <SoftTypography variant="button" fontWeight="light">
                  No Payment Methods Added
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          )}
        </Grid>
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddNewCard userId={user?.uid} handleClose={handleClose} />
      </Modal>
    </Card>
  );
}

export default PaymentMethods;
