import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import CurrencyFormat from "react-currency-format";

import SevereColdIcon from "@mui/icons-material/SevereCold";
import { useClubs } from "src/features/club/ClubProvider";

function PreHeatReimbursementForm({ addPreHeatFee, onAddPreHeatChanged }) {
  const { selectedLocation } = useClubs();

  const renderTotal = () => {
    if (addPreHeatFee && selectedLocation.preferences?.preHeatFee?.rate > 0) {
      return (
        <SoftTypography variant="caption" color="text" pl={2} fontSize="0.9rem">
          Reimbursement:{" "}
          <b>
            <CurrencyFormat
              value={selectedLocation.preferences?.preHeatFee?.rate || 0}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </b>
        </SoftTypography>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <SevereColdIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="dark"
              fontSize="medium"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Pre-Heat Reimbursement
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8}>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={2}
        >
          <SoftBox display="flex" flexDirection="row">
            <SoftBox ml={0.5} display="inline-block">
              <Checkbox checked={addPreHeatFee} onClick={onAddPreHeatChanged} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={onAddPreHeatChanged}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                Add Reimbursement
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {renderTotal()}
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
PreHeatReimbursementForm.propTypes = {
  onAddPreHeatChanged: PropTypes.func.isRequired,
  addPreHeatFee: PropTypes.bool.isRequired,
};

export default PreHeatReimbursementForm;
