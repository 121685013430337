import PropTypes from "prop-types";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import Swal from "sweetalert2";
import CopyToClipboard from "react-copy-to-clipboard";
import * as toastr from "toastr";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ActionsCell({
  onEdit,
  onDelete,
  tooltipLabel = "",
  onView,
  copyText,
}) {
  const deleteWrapper = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await onDelete();
        }
      });
  };
  return (
    <SoftBox display="flex" alignItems="center">
      {copyText && (
        <CopyToClipboard
          text={copyText}
          onCopy={() => toastr.success("Copied to clipboard")}
        >
          <SoftTypography
            variant="body1"
            color="secondary"
            sx={{
              cursor: "pointer",
              lineHeight: 0,
            }}
          >
            <Tooltip title={`Copy Direct Link Url`} placement="top">
              <ContentCopyIcon sx={{ marginLeft: 1 }} />
            </Tooltip>
          </SoftTypography>
        </CopyToClipboard>
      )}
      {onView && (
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{
            cursor: "pointer",
            lineHeight: 0,
          }}
          onClick={onView}
        >
          <Tooltip title={`Preview ${tooltipLabel}`} placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      )}
      {onEdit && (
        <SoftBox mx={2}>
          <SoftTypography
            variant="body1"
            color="secondary"
            sx={{
              cursor: "pointer",
              lineHeight: 0,
            }}
            onClick={onEdit}
          >
            <Tooltip title={`Edit ${tooltipLabel}`} placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
      )}
      {onDelete && (
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{
            cursor: "pointer",
            lineHeight: 0,
          }}
          onClick={deleteWrapper}
        >
          <Tooltip title={`Delete ${tooltipLabel}`} placement="left">
            <Icon>delete</Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}

ActionsCell.propTypes = {
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  copyText: PropTypes.string,
};

export default ActionsCell;
