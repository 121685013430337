import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";

import MemberInfo from "src/pages/people/manage-members/MemberInfo";
import { useClubs } from "src/features/club/ClubProvider";

export function UserInfoModal({ handleClose, userId }) {
  const { clubUsers } = useClubs();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!userId) return;
    setUser(clubUsers.get(userId));
  }, [userId]);

  if (!user) return null;

  return (
    <ModalContainer handleClose={handleClose}>
      <MemberInfo member={user} />
    </ModalContainer>
  );
}

UserInfoModal.propTypes = {
  handleClose: PropTypes.func,
  userId: PropTypes.string,
};
