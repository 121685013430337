import Dashboard from "src/pages/dashboards";
import BookFlight from "src/pages/book-flight";
import PaymentSettings from "src/pages/user/account/payment-methods";
import ProfileSettings from "src/pages/user/account/settings";
import BillingSettings from "src/pages/settings/billing";
import ClubSettings from "src/pages/settings/club";
import AircraftsCrud from "src/pages/resources/aircrafts";
import SimulatorsCrud from "src/pages/resources/simulators";
import ManageMembers from "src/pages/people/manage-members";
import TransactionList from "src/pages/club-billing/transaction-list";
import AccountList from "src/pages/club-billing/account-list";
import InstructorResources from "src/pages/resources/instructors";
import ReportsSummary from "src/pages/reports";
import ReservationsReport from "src/pages/reports/sub-reports/reservations/bookings";
import CheckInsReport from "src/pages/reports/sub-reports/flights/check-ins";
import FlightTimeReport from "src/pages/reports/sub-reports/flights/flight-time";
import CancellationsReport from "src/pages/reports/sub-reports/flights/cancellations";
import CheckOutsReport from "src/pages/reports/sub-reports/aircraft/check-outs";
import SquawksReport from "src/pages/reports/sub-reports/aircraft/squawks";
import MembershipsReport from "src/pages/reports/sub-reports/members/memberships";
import ExistingMembersReport from "src/pages/reports/sub-reports/members/existing-members";
import InstructorSummaryReport from "src/pages/reports/sub-reports/instructors";
import AircraftSummaryReport from "src/pages/reports/sub-reports/aircraft";
import PayrollReport from "src/pages/reports/sub-reports/instructors/payroll";
// import MySchedule from "src/pages/schedule/my-schedule";
// import InstructorsSchedule from "src/pages/schedule/instructors";
// import AircraftSchedule from "src/pages/schedule/aircraft";
import MxOverview from "src/pages/maintenance/overview";
// import WorkOrders from "src/pages/maintenance/work-orders";
import Squawks from "src/pages/maintenance/squawks";
import Reminders from "src/pages/maintenance/reminders";
import MeetingRoomsSchedule from "src/pages/schedule/meeting-rooms";
import EventsSchedule from "src/pages/schedule/events";
import ManageStudents from "src/pages/lms/students";
import ManageCourses from "src/pages/lms/courses";
import Classroom from "src/pages/lms/classroom";
import FeesReport from "src/pages/reports/sub-reports/financial/fees";
import RefundsReport from "src/pages/reports/sub-reports/financial/refunds";
import StripePayoutsReport from "src/pages/reports/sub-reports/financial/payouts";
import StripePaymentsReport from "src/pages/reports/sub-reports/financial/payments";
import IntroFlightList from "src/pages/settings/intro-flights/IntroFlightList";
import StudentProgressReport from "src/pages/reports/sub-reports/lms/progress";
import CheckRidesReport from "src/pages/reports/sub-reports/lms/checkrides";
import EnrolledStudentReport from "src/pages/reports/sub-reports/lms/enrolled-students";
// import ManageGroups from 'src/pages/people/manage-groups';
// import Documents from 'src/pages/documents';

// import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import GroupsIcon from "@mui/icons-material/Groups";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import BuildIcon from "@mui/icons-material/Build";
import SchoolIcon from "@mui/icons-material/School";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import UserFinanaceReport from "src/pages/reports/sub-reports/financial/users";
import UserRoles from "src/pages/settings/user-roles/UserRoles";
import LogBook from "src/pages/log-book/LogBook";
// import TopicIcon from '@mui/icons-material/Topic';
// import Reminders from 'src/pages/settings/maintenance/reminders';
// import BuildIcon from '@mui/icons-material/Build';

import { systemPermissions } from "src/interfaces/roles/role.interface";

const menuRoutes = [
  {
    type: "collapse",
    name: "Schedule",
    permissionKey: systemPermissions.VIEW_BOOK_A_FLIGHT,
    key: "schedule",
    route: "/schedule",
    component: <BookFlight />,
    icon: <EventIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "divider",
    key: "divider-1",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    icon: <DashboardIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    route: "/events",
    component: <EventsSchedule />,
    icon: <CalendarMonthIcon size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Schedule",
  //   key: "schedule",
  //   icon: <EventIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "My Schedule",
  //       key: "my-schedule",
  //       permissionKey: systemPermissions.VIEW_MY_SCHEDULE,
  //       route: "/schedule/my-schedule",
  //       component: <MySchedule />,
  //     },
  //     {
  //       name: "Instructors",
  //       key: "instructorsSchedule",
  //       permissionKey: systemPermissions.VIEW_INSTRUCTORS_SCHEDULE,
  //       route: "/schedule/instructorsSchedule",
  //       component: <InstructorsSchedule />,
  //     },
  //     {
  //       name: "Aircraft",
  //       key: "aircraftSchedule",
  //       permissionKey: systemPermissions.VIEW_AIRCRAFT_SCHEDULE,
  //       route: "/schedule/aircraftSchedule",
  //       component: <AircraftSchedule />,
  //     },
  //     {
  //       name: "Meeting Rooms",
  //       key: "meeting-rooms",
  //       permissionKey: systemPermissions.VIEW_MEETING_ROOMS,
  //       route: "/schedule/meeting-rooms",
  //       component: <MeetingRoomsSchedule />,
  //     },
  //     {
  //       name: "Events",
  //       key: "events",
  //       permissionKey: systemPermissions.VIEW_EVENTS,
  //       route: "/schedule/events",
  //       component: <EventsSchedule />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Resources",
    key: "resources",
    icon: <LocalAirportIcon size="12px" />,
    collapse: [
      {
        name: "Aircraft",
        key: "aircraft",
        permissionKey: systemPermissions.VIEW_AIRCRAFT,
        route: "/resources/aircraft",
        component: <AircraftsCrud />,
      },
      {
        name: "Simulators",
        key: "simulators",
        permissionKey: systemPermissions.VIEW_SIMULATORS,
        route: "/resources/simulators",
        component: <SimulatorsCrud />,
      },
      {
        name: "Instructors",
        key: "instructors",
        permissionKey: systemPermissions.VIEW_INSTRUCTORS,
        route: "/resources/instructors",
        component: <InstructorResources />,
      },
      // {
      //   name: 'Rooms',
      //   key: 'rooms',
      //   route: '/resources/rooms',
      //   component: <AllProjects />,
      // },
      // {
      //   name: 'Headsets',
      //   key: 'headsets',
      //   route: '/resources/headsets',
      //   component: <AllProjects />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "People",
    permissionKey: systemPermissions.VIEW_MEMBERS,
    key: "people",
    route: "/people",
    component: <ManageMembers />,
    icon: <GroupsIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Training",
    key: "training",
    icon: <SchoolIcon size="12px" />,
    collapse: [
      {
        name: "Classroom",
        key: "classroom",
        route: "/training/classroom",
        component: <Classroom />,
      },
      // {
      //   name: 'Syllabus',
      //   key: 'syllabus',
      //   route: '/training/syllabus',
      //   component: <InstructorDashboard />,
      // },
      // {
      //   name: 'Exams',
      //   key: 'exams',
      //   collapse: [
      //     {
      //       name: 'Practice Exam',
      //       key: 'practice-exam',
      //       route: '/training/exams/practice-exam',
      //       component: <InstructorDashboard />,
      //     },
      //     {
      //       name: 'Previous Results',
      //       key: 'previous-results',
      //       route: '/training/exams/previous-results',
      //       component: <InstructorDashboard />,
      //     },
      //   ],
      // },
    ],
  },
  {
    type: "collapse",
    name: "LMS",
    key: "lms",
    icon: <SchoolIcon size="12px" />,
    route: "/lms",
    collapse: [
      {
        name: "Students",
        key: "students",
        permissionKey: systemPermissions.VIEW_LMS_STUDENTS,
        route: "/lms/students",
        component: <ManageStudents />,
      },
      {
        name: "Courses",
        key: "courses",
        permissionKey: systemPermissions.VIEW_LMS_COURSES,
        route: "/lms/courses",
        component: <ManageCourses />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Maintenance",
    key: "maintenance",
    icon: <BuildIcon size="12px" />,
    route: "/maintenance",
    collapse: [
      {
        name: "Overview",
        key: "overview",
        permissionKey: systemPermissions.VIEW_MX_OVERVIEW,
        route: "/maintenance/overview",
        component: <MxOverview />,
      },
      // {
      //   name: "Work Orders",
      //   key: "work-orders",
      //   route: "/maintenance/work-orders",
      //   component: <WorkOrders />,
      // },
      {
        name: "Reminders",
        key: "reminders",
        permissionKey: systemPermissions.VIEW_MX_REMINDERS,
        route: "/maintenance/reminders",
        component: <Reminders />,
      },
      {
        name: "Squawks",
        key: "squawks",
        permissionKey: systemPermissions.VIEW_MX_SQUAWKS,
        route: "/maintenance/squawks",
        component: <Squawks />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <AttachMoneyIcon size="12px" />,
    route: "/billing",
    collapse: [
      {
        name: "Invoices",
        key: "invoices",
        permissionKey: systemPermissions.VIEW_INVOICES,
        route: "/billing/invoices",
        component: <TransactionList />,
      },
      {
        name: "Accounts",
        key: "accounts",
        permissionKey: systemPermissions.ACCESS_MEMBERS_ACCOUNTS,
        route: "/billing/accounts",
        component: <AccountList />,
      },
    ],
  },
  // {
  //   type: 'collapse',
  //   name: 'Documents',
  //   key: 'documents',
  //   icon: <TopicIcon size="12px" />,
  //   route: '/documents',
  //   noCollapse: true,
  //   component: <Documents />,
  // },
  // {
  //   type: 'collapse',
  //   name: 'Training',
  //   key: 'training',
  //   icon: <Basket size="12px" />,
  //   collapse: [
  //     {
  //       name: 'Syllabus Manager',
  //       key: 'syllabus-manager',
  //       route: '/template/ecommerce/overview',
  //       component: <Overview />,
  //     },
  //     {
  //       name: 'Student Groups',
  //       key: 'student-groups',
  //       route: '/template/ecommerce/overview',
  //       component: <Overview />,
  //     },
  //     {
  //       name: 'Exams',
  //       key: 'exams',
  //       collapse: [
  //         {
  //           name: 'Practice Exam',
  //           key: 'order-list',
  //           route: '/template/ecommerce/orders/order-list',
  //           component: <OrderList />,
  //         },
  //         {
  //           name: 'Previous Results',
  //           key: 'order-details',
  //           route: '/template/ecommerce/orders/order-details',
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Log Book",
    permissionKey: systemPermissions.VIEW_LOG_BOOK,
    key: "log-book",
    route: "/log-book",
    component: <LogBook />,
    icon: <SyncAltIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <SummarizeIcon size="12px" />,
    collapse: [
      {
        name: "Summary",
        key: "summary",
        permissionKey: systemPermissions.VIEW_REPORTS_SUMMARY,
        route: "/reports/summary",
        component: <ReportsSummary />,
      },
      {
        name: "Reservations",
        key: "reservations",
        permissionKey: systemPermissions.VIEW_RESERVATIONS_REPORTS,
        route: "/reports/reservations",
        component: <ReservationsReport />,
      },
      {
        name: "LMS",
        key: "lms",
        permissionKey: systemPermissions.VIEW_LMS_REPORTS,
        route: "/reports/lms",
        collapse: [
          {
            name: "Student Progress",
            key: "student-progress",
            route: "/reports/lms/student-progress",
            component: <StudentProgressReport />,
          },
          {
            name: "Checkrides",
            key: "checkrides",
            route: "/reports/lms/checkrides",
            component: <CheckRidesReport />,
          },
          {
            name: "Enrolled Students",
            key: "enrolledStudents",
            route: "/reports/lms/enrolledStudents",
            component: <EnrolledStudentReport />,
          },
        ],
      },
      {
        name: "Members",
        key: "members",
        permissionKey: systemPermissions.VIEW_MEMBER_REPORTS,
        route: "/reports/members",
        collapse: [
          {
            name: "Memberships",
            key: "memberships",
            route: "/reports/members/memberships",
            component: <MembershipsReport />,
          },
          {
            name: "Existing Members",
            key: "existing-members",
            route: "/reports/members/existing-members",
            component: <ExistingMembersReport />,
          },
        ],
      },
      {
        name: "Flights",
        key: "flights",
        permissionKey: systemPermissions.VIEW_FLIGHTS_REPORTS,
        route: "/reports/flights",
        collapse: [
          {
            name: "Cancellations",
            key: "cancellations",
            route: "/reports/flights/cancellations",
            component: <CancellationsReport />,
          },
          {
            name: "Check Ins",
            key: "check-ins",
            route: "/reports/flights/check-ins",
            component: <CheckInsReport />,
          },
          {
            name: "Flight Time",
            key: "flight-time",
            route: "/reports/flights/flight-time",
            component: <FlightTimeReport />,
          },
        ],
      },
      {
        name: "Instructors",
        key: "instructors",
        permissionKey: systemPermissions.VIEW_INSTRUCTORS_REPORTS,
        route: "/reports/instructors",
        collapse: [
          {
            name: "Summary",
            key: "summary",
            route: "/reports/instructors/summary",
            component: <InstructorSummaryReport />,
          },
          {
            name: "Payroll",
            key: "payroll",
            route: "/reports/instructors/payroll",
            component: <PayrollReport />,
          },
        ],
      },
      {
        name: "Aircraft",
        key: "aircraft",
        permissionKey: systemPermissions.VIEW_AIRCRAFT_REPORTS,
        route: "/reports/aircraft",
        collapse: [
          {
            name: "Summary",
            key: "summary",
            route: "/reports/aircraft/summary",
            component: <AircraftSummaryReport />,
          },
          {
            name: "Check Outs",
            key: "check-outs",
            route: "/reports/aircraft/check-outs",
            component: <CheckOutsReport />,
          },
          {
            name: "Squawks",
            key: "squawks",
            route: "/reports/aircraft/squawks",
            component: <SquawksReport />,
          },
          // {
          //   name: 'Maintenance Reminders',
          //   key: 'maintenance-reminders',
          //   route: '/reports/aircraft/mainenance-reminders',
          //   component: <AdminDashboard />,
          // },
        ],
      },
      {
        name: "Financial",
        key: "financial",
        permissionKey: systemPermissions.VIEW_FINANCIAL_REPORTS,
        route: "/reports/financial",
        collapse: [
          // {
          //   name: 'Products Sold',
          //   key: 'products',
          //   route: '/reports/financial/products',
          //   component: <AdminDashboard />,
          // },
          {
            name: "User Report",
            key: "user-report",
            route: "/reports/financial/user-report",
            component: <UserFinanaceReport />,
          },
          {
            name: "Payments",
            key: "payments",
            route: "/reports/financial/payments",
            component: <StripePaymentsReport />,
          },
          // {
          //   name: 'Adjustments',
          //   key: 'adjustments',
          //   route: '/reports/financial/adjustments',
          //   component: <AdminDashboard />,
          // },
          // {
          //   name: 'Taxes Owed',
          //   key: 'taxes-owed',
          //   route: '/reports/financial/taxes-owed',
          //   component: <AdminDashboard />,
          // },
          // {
          //   name: 'Accounts Receivable',
          //   key: 'accounts-receivable',
          //   route: '/reports/financial/accounts-receivable',
          //   component: <AdminDashboard />,
          // },
          {
            name: "Payouts",
            key: "payouts",
            route: "/reports/financial/payouts",
            component: <StripePayoutsReport />,
          },
          {
            name: "Refunds",
            key: "refunds",
            route: "/reports/financial/refunds",
            component: <RefundsReport />,
          },
          {
            name: "Fees",
            key: "fees",
            route: "/reports/financial/fees",
            component: <FeesReport />,
          },
        ],
      },
    ],
  },
  {
    type: "divider",
    key: "divider-2",
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "Account Settings",
        key: "account-settings",
        route: "/settings/account-settings",
        component: <ProfileSettings />,
      },
      {
        name: "Meeting Rooms",
        key: "meeting-rooms",
        permissionKey: systemPermissions.VIEW_MEETING_ROOMS,
        route: "/schedule/meeting-rooms",
        component: <MeetingRoomsSchedule />,
      },
      {
        name: "Club/School Settings",
        key: "club-settings",
        permissionKey: systemPermissions.VIEW_CLUB_SETTINGS,
        route: "/settings/club-settings",
        component: <ClubSettings />,
      },
      {
        name: "Billing Settings",
        key: "billing-settings",
        permissionKey: systemPermissions.VIEW_BILLING_SETTINGS,
        route: "/settings/billing-settings",
        component: <BillingSettings />,
      },
      {
        name: "Payment Methods",
        key: "payment-settings",
        route: "/settings/payment-settings",
        component: <PaymentSettings />,
      },
      {
        name: "Intro Flights",
        key: "intro-flight-settings",
        permissionKey: systemPermissions.VIEW_INTRO_FLIGHT_SETTINGS,
        route: "/settings/intro-flights",
        component: <IntroFlightList />,
      },
      {
        name: "User Roles",
        key: "user-roles",
        permissionKey: systemPermissions.VIEW_ROLES_SETTINGS,
        route: "/settings/user-roles",
        component: <UserRoles />,
      },
    ],
  },
];

export default menuRoutes;
