import { useParams, useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
// import { useUser } from "src/features/user/UserProvider";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { deleteDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import * as toastr from "toastr";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// OrderDetails page components
import Header from "./components/Header";
import PaymentDetails from "./components/PaymentDetails";
import PaymentError from "./components/PaymentError";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import { formatCurrency } from "src/features/utils";
import { getClubTransactionsCollection } from "src/features/transaction/collection";

function PaymentRequestDetails() {
  const { selectedClubId, selectedLocationId } = useClubs();
  // const { user } = useUser();
  const navigate = useNavigate();
  const functions = getFunctions();
  const { id } = useParams();
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const paymentRequestDocRef = doc(
    getClubTransactionsCollection(selectedClubId),
    id
  );
  const { data: paymentRequest, isDataLoaded: loadedPaymentRequest } =
    useRealtimeDocumentData(paymentRequestDocRef);

  const onResendPaymentRequest = async () => {
    // Resend payment request
    const resendPaymentRequest = httpsCallable(
      functions,
      "resendPaymentRequest"
    );
    resendPaymentRequest({
      clubId: selectedClubId,
      paymentRequestId: id,
    })
      .then((resendResult) => {
        if (resendResult.data.success) {
          toastr.success(
            `The payment request was resent to ${paymentRequest.pilot.firstName}.`,
            "Success"
          );
        } else {
          toastr.error(
            `There was an error resending the payment request to ${paymentRequest.pilot.firstName}.`,
            "Whoops!"
          );
        }
      })
      .catch(() => {
        toastr.error(
          `There was an error resending the payment request to ${paymentRequest.pilot.firstName}.`,
          "Whoops!"
        );
      });
  };

  const onCancelPaymentRequest = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel request!",
        cancelButtonText: "No, keep it!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deleteDoc(doc(getClubTransactionsCollection(selectedClubId), id));
          navigate("/billing/invoices");
        }
      });
  };
  const onMarkAsPaid = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will completed the payment request and deposit any Account Deposit funds specified to the member!",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          const completePaymentRequestWithoutPayment = httpsCallable(
            functions,
            "completePaymentRequestWithoutPayment"
          );
          completePaymentRequestWithoutPayment({
            paymentRequestId: id,
            clubId: selectedClubId,
            locationId: selectedLocationId,
          }).then((resendResult) => {
            if (resendResult.data.success) {
              toastr.success(
                `The payment request was marked as paid.`,
                "Success"
              );
            } else {
              toastr.error(
                `There was an error marking the payment request as paid`,
                "Whoops!"
              );
            }
          });
        }
      });
  };

  if (
    !paymentRequest?.createdAt &&
    loadedPaymentRequest &&
    paymentRequest?.id
  ) {
    // navigate("/billing");
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>
                  Can not find this payment request...
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (!loadedPaymentRequest || !paymentRequest) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={9}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>Loading...</SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <Header
                    paymentRequest={paymentRequest}
                    onResendPaymentRequest={onResendPaymentRequest}
                    onCancelPaymentRequest={onCancelPaymentRequest}
                    onMarkAsPaid={onMarkAsPaid}
                  />
                </SoftBox>
                <Divider />
                <SoftBox pt={1} pb={3} px={2}>
                  <SoftBox mb={3}>
                    <SoftBox p={3}>
                      <SoftBox width="100%" overflow="auto">
                        <Table
                          sx={{
                            minWidth: "32rem",
                          }}
                        >
                          <SoftBox component="thead">
                            <TableRow>
                              <SoftBox
                                component="th"
                                width={{
                                  xs: "45%",
                                  md: "50%",
                                }}
                                py={1.5}
                                px={1}
                                textAlign="left"
                                borderBottom={borderBottom}
                              >
                                <SoftTypography
                                  variant="h6"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Item
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="th"
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                borderBottom={borderBottom}
                              >
                                <SoftTypography
                                  variant="h6"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Qty
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="th"
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                borderBottom={borderBottom}
                              >
                                <SoftTypography
                                  variant="h6"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Rate
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="th"
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                borderBottom={borderBottom}
                              >
                                <SoftTypography
                                  variant="h6"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Discount
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="th"
                                py={1.5}
                                pl={3}
                                pr={1}
                                textAlign="left"
                                borderBottom={borderBottom}
                              >
                                <SoftTypography
                                  variant="h6"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Amount
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          </SoftBox>
                          <TableBody>
                            {paymentRequest?.lineItems?.map((item) => (
                              <TableRow key={item.id}>
                                <SoftBox
                                  component="td"
                                  textAlign="left"
                                  p={1}
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography variant="body2" color="text">
                                    {item.item}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography variant="body2" color="text">
                                    {item.qty}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography variant="body2" color="text">
                                    {formatCurrency(
                                      item.type === "credit" ||
                                        item.type === "accountBalance"
                                        ? item.price * -1
                                        : item.price
                                    )}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography variant="body2" color="text">
                                    {item.discount > 0 &&
                                      formatCurrency(item.discount)}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography variant="body2" color="text">
                                    {formatCurrency(
                                      item.type === "credit" ||
                                        item.type === "accountBalance"
                                        ? item.total * -1
                                        : item.total
                                    )}
                                  </SoftTypography>
                                </SoftBox>
                              </TableRow>
                            ))}
                            <TableRow key={"Total"}>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                p={1}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" />
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" />
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text" />
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  <b>Sub Total:</b>
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    paymentRequest?.lineItems?.reduce(
                                      (acc, item) =>
                                        item.type === "credit" ||
                                        item.type === "accountBalance"
                                          ? acc - item.total
                                          : acc + item.total,
                                      0
                                    )
                                  )}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </SoftBox>
                      <SoftBox mt={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <PaymentDetails paymentRequest={paymentRequest} />
                            {paymentRequest.payments?.[0]?.last_payment_error
                              ?.message && (
                              <SoftBox mt={3}>
                                <PaymentError transaction={paymentRequest} />
                              </SoftBox>
                            )}
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </>
    </DashboardLayout>
  );
}

export default PaymentRequestDetails;
