// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useState, useEffect, useMemo, createRef } from "react";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { firebaseApp } from "src/features/firebase";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import WeatherBarLabel from "./WeatherBar";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeline from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import scrollgridPlugin from "@fullcalendar/scrollgrid";

// @mui material components
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import DatePicker from "react-datepicker";

// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";

// import 'flatpickr/dist/themes/material_blue.css';
import "react-datepicker/dist/react-datepicker.css";
import { BookingModal } from "src/modals/BookingModal";
import { BookingErrorModal } from "src/modals/BookingErrorModal";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import {
  isWithinInstructorWorkingHours,
  getOverlappingBookings,
  isOverlappingAnotherBooking,
} from "src/pages/book-flight/utils";
import { sub, add } from "date-fns";
import CalendarFilter from "./CalendarFilter";
import { Divider } from "@mui/material";
import {
  getLocalUserSetting,
  setLocalUserSetting,
} from "src/features/user/LocalUserSettings";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { useClickOutside } from "src/hooks/useClickOutside";
import { getBackgroundColor, getTextColor } from "./uiHelper";
import SunCalc from "suncalc";

const validClassNames = [
  "maintenance",
  "maintenance-dispatched",
  "maintenance-dispatched-other",
  "maintenance-completed",
  "maintenance-completed-other",
  "requestOnly",
  "unavailable",
  "checkride",
  "checkout",
  "checkout-other",
  "pilotRefresher",
  "pilotRefresher-other",
  "reservation",
  "reservation-other",
  "introFlight",
  "introFlight-other",
  "dispatched",
  "dispatched-other",
  "completed",
  "completed-other",
  "warning",
  "error",
  "light",
  "dark",
];

const getClassName = (el, userId) => {
  if (!el.confirmed) return "event-unconfirmed";
  if ((el.transferRequestInstructor ?? []).includes(userId))
    return "event-unconfirmed";
  if (el.dispatched) {
    if (el.extendedProps?.type?.value === "maintenance") {
      if (
        el.extendedProps?.instructor?.value === userId ||
        el.extendedProps?.pilot?.value === userId ||
        el.extendedProps?.member?.value === userId
      )
        return `event-maintenance-dispatched`;
      return `event-maintenance-dispatched-other`;
    }
    if (
      el.extendedProps?.instructor?.value === userId ||
      el.extendedProps?.pilot?.value === userId ||
      el.extendedProps?.pilot2?.value === userId ||
      el.extendedProps?.member?.value === userId
    )
      return `event-dispatched`;
    return `event-dispatched-other`;
  }

  return validClassNames.find((item) => item === el.className)
    ? `event-${el.className}`
    : "event-info";
};

function renderWeatherLabelContent(eventInfo) {
  const labelDate = eventInfo.date;
  if (
    eventInfo?.view?.type === "resourceTimeGridFourDay" &&
    eventInfo.level === 1
  ) {
    return <WeatherBarLabel label={eventInfo.text} date={labelDate} />;
  }

  if (
    eventInfo?.view?.type === "resourceTimeline" ||
    eventInfo?.view?.type === "resourceTimeGridDay"
  ) {
    return <WeatherBarLabel label={eventInfo.text} date={labelDate} />;
  }
}

function Calendar({ header, resourceData, pilots, ...rest }) {
  const { userId } = useUser();
  const { selectedLocation, selectedLocationId, locationReservationTypes } =
    useClubs();
  const { hasAccess } = usePermissions();
  const { canCreateBooking, canEditBooking, isUserInAircraftMembershipLevel } =
    useUserPermissions();
  const [openSnack, setOpenSnack] = useState(false);
  const [selection, setSelection] = useState();
  const [events, setEvents] = useState([]);
  const [errorBooking, setErrorBooking] = useState({});
  const [permissionErrors, setPermissionErrors] = useState([]);
  const [currentView, setCurrentView] = useState(null);
  const [currentDates, setCurrentDates] = useState(null);
  const handleSetSelection = (data) => setSelection(data);
  const calendarRef = createRef();

  const [filteredInstructorResources, setFilteredInstructorResources] =
    useState(
      resourceData
        .filter((el) => el.type === "Instrutors")
        .filter((fir) => {
          return (
            getLocalUserSetting(userId)?.[
              selectedLocationId
            ]?.filteredInstructorResources?.some((rd) => rd.id === fir.id) ||
            true
          );
        })
    );
  const [filteredAircraftResources, setFilteredAircraftResources] = useState(
    resourceData
      .filter((el) => el.type === "Aircraft" || el.type === "Simulators")
      .filter((fir) => {
        return (
          getLocalUserSetting(userId)?.[
            selectedLocationId
          ]?.filteredAircraftResources?.some((rd) => rd.id === fir.id) || true
        );
      })
  );
  const [filteredMeetingRoomResources, setFilteredMeetingRoomResources] =
    useState(
      resourceData
        .filter((el) => el.type === "MeetingRooms")
        .filter((fir) => {
          return (
            getLocalUserSetting(userId)?.[
              selectedLocationId
            ]?.filteredMeetingRoomResources?.some((rd) => rd.id === fir.id) ||
            true
          );
        })
    );

  useEffect(() => {
    if (!resourceData || !selectedLocationId) return;

    setFilteredInstructorResources(
      resourceData
        .filter((el) => el.type === "Instructors")
        .filter((fir) => {
          return (
            getLocalUserSetting(userId)?.[
              selectedLocationId
            ]?.filteredInstructorResources?.some((rd) => rd.id === fir.id) ||
            true
          );
        })
    );
    setFilteredAircraftResources(
      resourceData
        .filter((el) => el.type === "Aircraft" || el.type === "Simulators")
        .filter((fir) => {
          return (
            getLocalUserSetting(userId)?.[
              selectedLocationId
            ]?.filteredAircraftResources?.some((rd) => rd.id === fir.id) || true
          );
        })
    );
    setFilteredMeetingRoomResources(
      resourceData
        .filter((el) => el.type === "MeetingRooms")
        .filter((fir) => {
          return (
            getLocalUserSetting(userId)?.[
              selectedLocationId
            ]?.filteredMeetingRoomResources?.some((rd) => rd.id === fir.id) ||
            true
          );
        })
    );
  }, [resourceData, selectedLocationId]);

  useEffect(() => {
    const previousSelectedView = getLocalUserSetting(userId)?.calendarView;
    if (previousSelectedView) {
      calendarRef.current.getApi().changeView(previousSelectedView);
    }
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width <= 730;

  const [showBookingErrorModal, setShowBookingErrorModal] = useState(false);
  const handleCloseBookingErrorModal = (refreshEvents) => {
    if (refreshEvents) setupEvents();
    setPermissionErrors([]);
    setShowBookingErrorModal(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleSetSelection(null);
    setOpen(false);
  };

  const setupEvents = () => {
    const eventArray = [];
    if (rest.events?.length > 0) {
      rest.events.map((el) => {
        if (
          el.extendedProps.aircraft?.value &&
          el.extendedProps.aircraft?.value !== "" &&
          el.extendedProps.instructor?.value &&
          el.extendedProps.instructor?.value !== "" &&
          (el.preGround > 0 || el.postGround > 0)
        ) {
          eventArray.push({
            ...el,
            groupId: el.id,
            id: `dup_${el.id}`,
            resourceEditable: canEditBooking(el),
            resourceIds: [el.extendedProps?.instructor?.value],
            display:
              el.extendedProps?.type?.value === "requestOnly" ||
              el.extendedProps?.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el, userId),
            backgroundColor: getBackgroundColor(
              el,
              userId,
              locationReservationTypes
            ),
            textColor: getTextColor(el, userId, locationReservationTypes),
            start: sub(el.start, { minutes: el.preGround }),
            end: add(el.end, { minutes: el.postGround }),
          });
          eventArray.push({
            ...el,
            groupId: el.id,
            resourceEditable: canEditBooking(el),
            resourceIds: [el.extendedProps?.aircraft?.value],
            backgroundColor: getBackgroundColor(
              el,
              userId,
              locationReservationTypes
            ),
            textColor: getTextColor(el, userId, locationReservationTypes),
            display:
              el.extendedProps.type?.value === "requestOnly" ||
              el.extendedProps.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el, userId),
          });
        } else {
          eventArray.push({
            ...el,
            resourceEditable: canEditBooking(el),
            backgroundColor: getBackgroundColor(
              el,
              userId,
              locationReservationTypes
            ),
            textColor: getTextColor(el, userId, locationReservationTypes),
            display:
              el.extendedProps.type?.value === "requestOnly" ||
              el.extendedProps.type?.value === "unavailable"
                ? "background"
                : "block",
            overlap:
              el.extendedProps.type?.value !== "unavailable" &&
              el.extendedProps.type?.value !== "maintenance",
            className: getClassName(el, userId),
            start: sub(el.start, { minutes: el.preGround ?? 0 }),
            end: add(el.end, { minutes: el.postGround ?? 0 }),
          });
        }
      });
    }
    setEvents(eventArray);
  };

  useEffect(() => {
    setupEvents();
  }, [rest.events]);

  const eventsMemo = useMemo(
    () =>
      events.filter((event) =>
        event.resourceIds.some(
          (id) =>
            filteredAircraftResources.some((el) => el.id === id) ||
            filteredInstructorResources.some((el) => el.id === id) ||
            filteredMeetingRoomResources.some((el) => el.id === id)
        )
      ),
    [
      events,
      filteredAircraftResources,
      filteredInstructorResources,
      filteredMeetingRoomResources,
    ]
  );

  const handleSelectionOverlap = (event) => {
    if (hasAccess([systemPermissions.ALLOW_OVERLAPPING_BOOKINGS])) return true;
    if (
      selectedLocation.preferences?.calendar?.allowOverlappingBookings &&
      event.extendedProps?.type?.value !== "unavailable" &&
      event.extendedProps?.type?.value !== "maintenance"
    ) {
      return true;
    }

    if (event.extendedProps?.type?.value === "requestOnly") return true;

    return false;
  };

  const handleSelection = (data) => {
    handleSetSelection(data);
    handleOpen();
  };

  const saveDateChanges = async (data) => {
    console.log("SAVE DATE CHANGES", data);
    const id = data?.event?.id.replace("dup_", "");
    let { start, end } = data.event;
    if (
      data?.event?.getResources()[0]?.id ===
      data?.event?.extendedProps?.instructor?.value
    ) {
      start = add(start, {
        minutes: data?.event?.extendedProps?.preGround ?? 0,
      });
      end = sub(end, { minutes: data?.event?.extendedProps?.postGround ?? 0 });
    }

    const extendedProps = {
      aircraft: data.event.extendedProps.aircraft,
      clubId: data.event.extendedProps.clubId,
      locationId: data.event.extendedProps.locationId,
      type: data.event.extendedProps.type,
      instructor: data.event.extendedProps.instructor,
      pilot: data.event.extendedProps.pilot,
      pilot2: data.event.extendedProps.pilot2,
    };

    let resourceIds = [];
    data.event.getResources().forEach((resource) => {
      resourceIds.push(resource?.id);
    });
    data.event.resourceIds = resourceIds;

    let hasErrors = false;

    if (!hasAccess(systemPermissions.ALLOW_OVERLAPPING_BOOKINGS)) {
      const isOverlapping = await isOverlappingAnotherBooking(
        data.event,
        selectedLocation.preferences
      );
      if (isOverlapping) {
        hasErrors = true;
        setPermissionErrors(
          permissionErrors.concat(["Overlapping another booking"])
        );
      }
    }

    const checkUserInAircraftMembershipLevel =
      await isUserInAircraftMembershipLevel(data.event);
    if (!checkUserInAircraftMembershipLevel.allowed) {
      hasErrors = true;
      setPermissionErrors(
        permissionErrors.concat([
          `You must have a ${checkUserInAircraftMembershipLevel.reason} membership to book this aircraft`,
        ])
      );
    }
    const permissionCheck = await canCreateBooking(data.event);
    if (!permissionCheck.allowed) {
      hasErrors = true;
      setPermissionErrors(permissionErrors.concat(permissionCheck.reasons));
    }

    console.log("SAVE DATE CHANGES DOC", {
      clubId: extendedProps.clubId,
      locationId: extendedProps.locationId,
      bookingId: id,
    });
    const bookingRef = doc(
      getFirestore(firebaseApp),
      `clubs/${extendedProps.clubId}/locations/${extendedProps.locationId}/bookings/${id}`
    );

    if (
      data.oldResource &&
      data.newResource &&
      (data.oldResource.extendedProps.type ===
        data.newResource.extendedProps.type ||
        ((data.oldResource.extendedProps.type === "Aircraft" ||
          data.oldResource.extendedProps.type === "Simulators") &&
          (data.newResource.extendedProps.type === "Aircraft" ||
            data.newResource.extendedProps.type === "Simulators")))
    ) {
      if (
        data.oldResource.extendedProps.type === "Aircraft" ||
        data.oldResource.extendedProps.type === "Simulators"
      ) {
        extendedProps.aircraft = {
          label: data.newResource.title,
          value: data.newResource.id,
        };
      } else if (data.oldResource.extendedProps.type === "Instructors") {
        extendedProps.instructor = {
          label: data.newResource.title,
          value: data.newResource.id,
        };
      }
    }

    resourceIds = [];
    if (extendedProps.aircraft?.value)
      resourceIds.push(extendedProps.aircraft?.value);
    if (extendedProps.instructor?.value)
      resourceIds.push(extendedProps.instructor?.value);
    if (extendedProps.pilot?.value)
      resourceIds.push(extendedProps.pilot?.value);
    if (extendedProps.pilot2?.value)
      resourceIds.push(extendedProps.pilot2?.value);
    if (extendedProps.member?.value)
      resourceIds.push(extendedProps.member?.value);

    data.event.resourceIds = resourceIds;

    let confirmed = true;

    const isWithinWorking = await isWithinInstructorWorkingHours(data.event);
    const overlappingBookings = await getOverlappingBookings(data.event);
    if (
      extendedProps.type?.value !== "maintenance" &&
      (!isWithinWorking || overlappingBookings.requestOnly.length > 0) &&
      userId !== extendedProps?.instructor?.value
    ) {
      confirmed = false;
    }

    const bookingDataToSave = {
      id,
      start,
      end,
      confirmed,
      extendedProps,
      resourceIds,
      updatedAt: new Date(),
      updatedBy: userId,
    };

    if (hasErrors) {
      setErrorBooking(bookingDataToSave);
      setShowBookingErrorModal(true);
    } else {
      rest.events[
        rest.events.findIndex((event) => event.id === bookingDataToSave.id)
      ] = {
        ...rest.events.find((event) => event.id === bookingDataToSave.id),
        ...bookingDataToSave,
      };
      await updateDoc(bookingRef, bookingDataToSave);
    }
  };

  const setCalendarDate = (date) => {
    calendarRef.current.getApi().gotoDate(date);
  };

  const setMonth = (amount) => {
    const currentDate = calendarRef.current.getApi().view.currentStart;
    if (currentDate) {
      const newDate = add(currentDate, { months: amount });
      setCalendarDate(newDate);
    }
  };

  const datepickerRef = useClickOutside(() => setIsOpen(false));

  const [startDate, setStartDate] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (e) => {
    setIsOpen(!isOpen);
    setCalendarDate(e);
  };

  const showDatePicker = (e) => {
    e.preventDefault();
    setStartDate(calendarRef?.current?.getApi().view.currentStart);
    setIsOpen(!isOpen);
  };

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleDatesSet = (info) => {
    setCurrentView(info.view.type);
    setCurrentDates({ start: info.start, end: info.end });
    setLocalUserSetting(userId, { calendarView: info.view.type });
    if (rest.datesSet) rest.datesSet(info);
  };

  useEffect(() => {
    const sunriseIndicator = document.querySelector(`.sunrise-indicator`);
    if (sunriseIndicator) {
      sunriseIndicator.remove(); // Remove the element from the DOM
    }
    const sunsetIndicator = document.querySelector(`.sunset-indicator`);
    if (sunsetIndicator) {
      sunsetIndicator.remove(); // Remove the element from the DOM
    }

    const lat = selectedLocation?.latitude;
    const lon = selectedLocation?.longitude;

    if (!lat || !lon || !currentDates?.start) return;

    const calendarApi = calendarRef.current.getApi();

    var times = SunCalc.getTimes(currentDates.start, lat, lon);
    var sunriseStr =
      times.sunrise.getHours() +
      ":" +
      times.sunrise.getMinutes() +
      ":" +
      times.sunrise.getSeconds();
    var sunsetStr =
      times.sunset.getHours() +
      ":" +
      times.sunset.getMinutes() +
      ":" +
      times.sunset.getSeconds();

    if (currentView === "resourceTimeline") {
      addVerticalIndicator(
        calendarApi,
        sunriseStr,
        "sunrise-indicator",
        "orange",
        "Sunrise"
      );
      addVerticalIndicator(
        calendarApi,
        sunsetStr,
        "sunset-indicator",
        "red",
        "Sunset"
      );
    } else {
      addHorizontalIndicator(
        calendarApi,
        sunriseStr,
        "sunrise-indicator",
        "orange",
        "Sunrise"
      );
      addHorizontalIndicator(
        calendarApi,
        sunsetStr,
        "sunset-indicator",
        "red",
        "Sunset"
      );
    }
  }, [currentView, currentDates, selectedLocation]);

  const addHorizontalIndicator = (
    calendarApi,
    time,
    className,
    color,
    title
  ) => {
    const calendarEl = calendarApi.el;
    const timeGrid = calendarEl.querySelector(".fc-timegrid-slots");
    if (timeGrid) {
      const indicator = document.createElement("div");
      indicator.className = `custom-indicator ${className}`;
      indicator.style.position = "absolute";
      indicator.style.top = `${calculatePosition(time)}%`; // Calculate the position dynamically
      indicator.style.width = "100%";
      indicator.style.height = "1px";
      indicator.style.zIndex = 999;
      indicator.style.backgroundColor = color;
      timeGrid.appendChild(indicator);

      const indicatorLabel = document.createElement("div");
      indicatorLabel.className = `custom-indicator-label ${className}`;
      indicatorLabel.style.position = "sticky";
      indicatorLabel.style.marginTop = `-7.5px`;
      indicatorLabel.style.top = `0px`;
      indicatorLabel.style.left = "0px";
      indicatorLabel.style.height = "15px";
      indicatorLabel.style.width = "45px";
      indicatorLabel.style.paddingLeft = "4px";
      indicatorLabel.style.backgroundColor = color;
      indicatorLabel.style.fontSize = "10px";
      indicatorLabel.style.color = "#FFF";
      indicatorLabel.style.zIndex = 999;
      indicatorLabel.textContent = title;
      indicator.appendChild(indicatorLabel);
    }
  };
  const addVerticalIndicator = (calendarApi, time, className, color, title) => {
    const calendarEl = calendarApi.el;
    const timeGrid = calendarEl.querySelector(".fc-timeline-slots");
    if (timeGrid) {
      const indicator = document.createElement("div");
      indicator.className = `custom-indicator ${className}`;
      indicator.style.position = "absolute";
      indicator.style.top = "0";
      indicator.style.left = `${calculatePosition(time)}%`; // Calculate the position dynamically
      indicator.style.height = "100%";
      indicator.style.width = "1px";
      indicator.style.backgroundColor = color;
      timeGrid.appendChild(indicator);

      const indicatorLabel = document.createElement("div");
      indicatorLabel.className = `custom-indicator-label ${className}`;
      indicatorLabel.style.position = "absolute";
      indicatorLabel.style.top = "0px";
      indicatorLabel.style.left = `calc(${calculatePosition(time)}% - 20px)`; // Calculate the position dynamically
      indicatorLabel.style.height = "15px";
      indicatorLabel.style.width = "40px";
      indicatorLabel.style.paddingLeft = "4px";
      indicatorLabel.style.backgroundColor = color;
      indicatorLabel.style.fontSize = "10px";
      indicatorLabel.style.color = "#FFF";
      indicatorLabel.textContent = title;
      indicator.appendChild(indicatorLabel);
    }
  };

  const calculatePosition = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + seconds / 60;
    return (totalMinutes / (24 * 60)) * 100; // Convert to percentage
  };

  return (
    <SoftBox>
      <SoftBox
        pt={2}
        pb={1}
        px={2}
        lineHeight={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={isMobileSize ? "center" : "space-between"}
      >
        <SoftBox
          sx={{
            display: "inline-flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {resourceData.filter((el) => el.type === "Instructors").length >
            0 && (
            <CalendarFilter
              label="Instructors"
              placeholder="All Instructors"
              defaultValues={filteredInstructorResources}
              options={resourceData.filter((el) => el.type === "Instructors")}
              onChange={(e) => {
                setFilteredInstructorResources(e);
                setLocalUserSetting(userId, {
                  [selectedLocationId]: {
                    ...getLocalUserSetting(userId)?.[selectedLocationId],
                    filteredInstructorResources: e,
                  },
                });
              }}
            />
          )}
          {resourceData.filter(
            (el) => el.type === "Aircraft" || el.type === "Simulators"
          ).length > 0 && (
            <CalendarFilter
              label="Aircraft"
              placeholder="All Aircraft"
              defaultValues={filteredAircraftResources}
              options={resourceData.filter(
                (el) => el.type === "Aircraft" || el.type === "Simulators"
              )}
              onChange={(e) => {
                setFilteredAircraftResources(e);
                setLocalUserSetting(userId, {
                  [selectedLocationId]: {
                    ...getLocalUserSetting(userId)?.[selectedLocationId],
                    filteredAircraftResources: e,
                  },
                });
              }}
            />
          )}
          {resourceData.filter((el) => el.type === "MeetingRooms").length >
            0 && (
            <CalendarFilter
              label="Meeting Rooms"
              placeholder="All Meeting Rooms"
              defaultValues={filteredMeetingRoomResources}
              options={resourceData.filter((el) => el.type === "MeetingRooms")}
              onChange={(e) => {
                setFilteredMeetingRoomResources(e);
                setLocalUserSetting(userId, {
                  [selectedLocationId]: {
                    ...getLocalUserSetting(userId)?.[selectedLocationId],
                    filteredMeetingRoomResources: e,
                  },
                });
              }}
            />
          )}
        </SoftBox>
      </SoftBox>
      <Divider />
      {header && (
        <SoftBox px={2} lineHeight={1}>
          {header.title ? (
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {header.title}
            </SoftTypography>
          ) : null}
          {header.date ? (
            <SoftTypography
              component="p"
              variant="button"
              color="text"
              fontWeight="medium"
            >
              {header.date}
            </SoftTypography>
          ) : null}
        </SoftBox>
      )}
      <CalendarRoot p={0} pt={0}>
        <SoftBox
          id="datePicker"
          ref={datepickerRef}
          sx={{
            position: "absolute",
            zIndex: 999,
            top: isMobileSize ? "130px" : "80px",
          }}
        >
          {isOpen && (
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              inline
            />
          )}
        </SoftBox>
        {isMobileSize ? (
          <SoftBox
            sx={{
              "& .fc .fc-toolbar.fc-header-toolbar": {
                flexDirection: "column",
                marginBottom: 2,
                ".fc-toolbar-chunk:nth-child(2)": {
                  marginBottom: 2,
                },
              },
              "& .fc .fc-resource .fc-icon": {
                display: "none",
              },
            }}
            onClick={() => {
              handleOpenSnack();
            }}
          >
            <FullCalendar
              {...rest}
              // timeZone={selectedClub?.timeZone || "local"}
              ref={calendarRef}
              refetchResourcesOnNavigate="true"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                scrollgridPlugin,
                resourceTimeline,
                resourceTimeGridPlugin,
                bootstrap5Plugin,
              ]}
              themeSystem="bootstrap5"
              events={eventsMemo}
              schedulerLicenseKey={
                import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY
              }
              customButtons={{
                datePicker: {
                  text: "Date",
                  icon: "calendar",
                  id: "datePicker",
                  click: showDatePicker,
                },
              }}
              headerToolbar={{
                left: "",
                center: "prev,title,next",
                right:
                  "datePicker,today resourceTimeGridDay,resourceTimeline,timeGridDay",
              }}
              longPressDelay={250}
              initialView={
                getLocalUserSetting(userId)?.calendarView || "resourceTimeline"
              }
              views={{
                resourceTimeline: {
                  type: "resourceTimeline",
                  duration: {
                    days: 1,
                  },
                  buttonText: "Horizontal",
                },
                resourceTimeGridDay: {
                  type: "resourceTimeGridDay",
                  duration: {
                    days: 1,
                  },
                  buttonText: "Vertical",
                },
              }}
              resourceGroupField="type"
              resources={[
                ...(filteredAircraftResources?.length >= 0 &&
                  filteredAircraftResources),
                ...(filteredInstructorResources?.length >= 0 &&
                  filteredInstructorResources),
                ...(filteredMeetingRoomResources?.length >= 0 &&
                  filteredMeetingRoomResources),
              ]}
              height="auto"
              resourceOrder="type"
              // dateClick={handleSelection}
              select={handleSelection}
              selectOverlap={handleSelectionOverlap}
              eventDrop={saveDateChanges}
              eventResize={saveDateChanges}
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              slotMinWidth={
                selectedLocation.preferences?.calendar?.slotDuration ?? 15
              }
              slotDuration={{
                minutes:
                  selectedLocation.preferences?.calendar?.slotDuration ?? 15,
              }}
              scrollTimeReset={false}
              scrollTime="00:00:00"
              titleFormat={{
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }}
              datesSet={handleDatesSet}
              dayMinWidth={100}
              selectable
              nowIndicator
            />
          </SoftBox>
        ) : (
          <FullCalendar
            {...rest}
            // timeZone={selectedClub?.timeZone || "local"}
            ref={calendarRef}
            refetchResourcesOnNavigate="true"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              scrollgridPlugin,
              interactionPlugin,
              resourceTimeline,
              resourceTimeGridPlugin,
              bootstrap5Plugin,
            ]}
            dayMinWidth={100}
            contentHeight="auto"
            themeSystem="bootstrap5"
            // slotLabelContent={renderWeatherLabelContent}
            events={[...eventsMemo]}
            schedulerLicenseKey={import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY}
            customButtons={{
              datePicker: {
                text: "Date",
                icon: "calendar",
                id: "datePicker",
                click: showDatePicker,
              },
              prevMonth: {
                icon: "chevron-double-left",
                click: () => {
                  setMonth(-1);
                },
              },
              nextMonth: {
                icon: "chevron-double-right",
                click: () => {
                  setMonth(1);
                },
              },
            }}
            headerToolbar={{
              left: "datePicker today",
              center: "prevMonth prev,title,next nextMonth",
              right:
                "resourceTimeGridDay,resourceTimeline,timeGridDay,timeGridWeek,dayGridMonth",
            }}
            initialView={
              getLocalUserSetting(userId)?.calendarView || "resourceTimeline"
            }
            views={{
              timeGridDay: {
                type: "timeGridDay",
                slotDuration: {
                  minutes: 30,
                },
                duration: {
                  days: 1,
                },
              },
              resourceTimeline: {
                type: "resourceTimeline",
                duration: {
                  days: 1,
                },
                slotLabelContent: renderWeatherLabelContent,
                buttonText: "Horizontal",
                resourceAreaWidth: 220,
              },
              resourceTimeGridDay: {
                type: "resourceTimeGridDay",
                duration: {
                  days: 1,
                },
                slotMinWidth: 250,
                // resourceLabelContent: renderResourceLabelContent,
                slotLabelContent: renderWeatherLabelContent,
                buttonText: "Vertical",
              },
            }}
            resourceGroupField="type"
            resources={[
              ...(filteredAircraftResources?.length >= 0
                ? filteredAircraftResources
                : []),
              ...(filteredInstructorResources?.length >= 0
                ? filteredInstructorResources
                : []),
              ...(filteredMeetingRoomResources?.length >= 0
                ? filteredMeetingRoomResources
                : []),
            ]}
            height="auto"
            resourceOrder="payload.index"
            // dateClick={handleSelection}
            select={handleSelection}
            selectOverlap={handleSelectionOverlap}
            eventDrop={saveDateChanges}
            eventResize={saveDateChanges}
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            slotMinWidth={
              selectedLocation.preferences?.calendar?.slotDuration ?? 15
            }
            slotDuration={{
              minutes:
                selectedLocation.preferences?.calendar?.slotDuration ?? 15,
            }}
            // nowIndicatorContent={renderNowIndicator}
            // nowIndicatorClassNames={[
            //   "fc-now-indicator",
            //   // "sunrise-indicator-line",
            //   // "sunset-indicator-line",
            // ]}
            scrollTimeReset={false}
            scrollTime="00:00:00"
            titleFormat={{
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            }}
            datesSet={handleDatesSet}
            selectable
            nowIndicator
          />
        )}
      </CalendarRoot>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BookingModal
          selection={selection}
          handleClose={handleClose}
          resources={resourceData}
          pilots={pilots}
        />
      </Modal>
      <Modal
        open={showBookingErrorModal}
        onClose={handleCloseBookingErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BookingErrorModal
          booking={errorBooking}
          permissionErrors={permissionErrors}
          handleClose={handleCloseBookingErrorModal}
        />
      </Modal>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ flexDirection: "column" }}
        autoHideDuration={1700}
        onClose={handleCloseSnack}
        message="Long-press & drag to book"
      />
    </SoftBox>
  );
}

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  resourceData: PropTypes.array,
  pilots: PropTypes.array,
};

export default Calendar;
