import { useEffect, useRef } from "react";
import md5 from "md5";

import brand from "src/assets/images/logo.png";
import brandIcon from "src/assets/images/logo-icon.png";
import brandXmas from "src/assets/images/themed-logos/Santa.png";
import brandIconXmas from "src/assets/images/themed-logos/Santa-Icon.png";
import brandValentines from "src/assets/images/themed-logos/Valentines.png";
import brandIconValentines from "src/assets/images/themed-logos/Valentines-Icon.png";
import brandNewYears from "src/assets/images/themed-logos/NewYears.png";
import brandIconNewYears from "src/assets/images/themed-logos/NewYears-Icon.png";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatCurrency = (amount) => currencyFormatter.format(amount);

const roundNumber = (num) => Math.round(num * 100) / 100;

const isDev = () =>
  !import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === "development";

const getBrandIcons = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (month === 12) {
    return { brand: brandXmas, brandIcon: brandIconXmas };
  }
  if (month === 1 && day <= 7) {
    return { brand: brandNewYears, brandIcon: brandIconNewYears };
  }
  if (month === 2 && day === 14) {
    return { brand: brandValentines, brandIcon: brandIconValentines };
  }
  return { brand, brandIcon };
};

const stringToPastelColor = (str) => {
  const md5Hash = str;
  let hash = 0;
  for (let i = 0; i < md5Hash.length; i += 1) {
    hash = md5Hash.charCodeAt(i) + ((hash << 6) - hash);
    hash &= hash;
  }
  const shortened = hash % 360;
  return `hsl(${shortened},100%,40%)`;
};

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );

const convertStringToCamelCase = (sentence) =>
  sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (camelCaseMatch, i) => {
    if (+camelCaseMatch === 0) return "";
    return i === 0 ? camelCaseMatch.toLowerCase() : toTitleCase(camelCaseMatch);
  });

const calculateFees = (
  amount,
  fuelCost,
  preHeatCost,
  clubPaysFees,
  dismissPreFlightFee,
  selectedPaymentMethod
) => {
  if (amount === 0 && fuelCost === 0 && preHeatCost === 0) {
    return {
      totalCharge: 0,
      preflightFees: 0,
      stripeConnectFees: 0,
      stripeCCFees: 0,
      totalStripeFees: 0,
      applicationFee: 0,
      costToPilot: 0,
      clubRevenue: 0,
      chargeToClub: 0,
      chargeToPilot: 0,
    };
  }
  const totalCharge = roundNumber(
    amount - (fuelCost ?? 0) - (preHeatCost ?? 0)
  );
  let preflightFees = dismissPreFlightFee ? 0 : roundNumber(amount * 0.005);
  if (preflightFees < 0.5) {
    preflightFees = 0;
  }
  const stripeConnectFees =
    !selectedPaymentMethod || selectedPaymentMethod === ""
      ? 0
      : roundNumber(totalCharge <= 0 ? 0 : totalCharge * 0.0025 + 0.25);
  // const stripeConnectFees = 0;

  if (clubPaysFees) {
    let stripeCCFees = 0;
    if (totalCharge > 0) {
      if (selectedPaymentMethod?.includes("pm_")) {
        stripeCCFees = roundNumber(
          totalCharge <= 0 ? 0 : (1000 * totalCharge + 300) / 971 - totalCharge
        );
      } else if (selectedPaymentMethod?.includes("tmr_")) {
        stripeCCFees = roundNumber(
          totalCharge <= 0 ? 0 : (1000 * totalCharge + 50) / 973 - totalCharge
        );
      }
    }

    const totalStripeFees = stripeCCFees + stripeConnectFees;
    const applicationFee = totalStripeFees + preflightFees;

    const costToPilot = totalCharge;
    const clubRevenue = roundNumber(
      totalCharge - applicationFee < 0
        ? (totalCharge - applicationFee) * 1.015
        : totalCharge - applicationFee
    );
    const chargeToClub = roundNumber(
      (costToPilot <= 0 ? applicationFee : 0) * 1.015
    );
    const chargeToPilot = Math.max(costToPilot, 0);

    return {
      totalCharge,
      preflightFees,
      stripeConnectFees,
      stripeCCFees,
      totalStripeFees,
      applicationFee,
      costToPilot,
      clubRevenue,
      chargeToClub,
      chargeToPilot,
    };
  }

  const subTotal = totalCharge + preflightFees + stripeConnectFees;
  let stripeCCFees = 0;
  if (totalCharge > 0) {
    if (selectedPaymentMethod?.includes("pm_")) {
      stripeCCFees = roundNumber(
        totalCharge <= 0
          ? (1000 * preflightFees + 300) / 971 - preflightFees
          : (1000 * subTotal + 300) / 971 - subTotal
      );
    } else if (selectedPaymentMethod?.includes("tmr_")) {
      stripeCCFees = roundNumber(
        totalCharge <= 0
          ? (1000 * preflightFees + 50) / 973 - preflightFees
          : (1000 * subTotal + 50) / 973 - subTotal
      );
    }
  }

  const totalStripeFees = stripeCCFees + stripeConnectFees;
  const applicationFee = totalStripeFees + preflightFees;

  const costToPilot = totalCharge + applicationFee;
  const clubRevenue = totalCharge;
  const chargeToClub = 0;
  const chargeToPilot = Math.max(costToPilot, applicationFee);

  return {
    totalCharge,
    preflightFees,
    stripeConnectFees,
    stripeCCFees,
    totalStripeFees,
    applicationFee,
    costToPilot,
    clubRevenue,
    chargeToClub,
    chargeToPilot,
  };
};

const calculateAchFees = (amount, clubPaysFees, dismissPreFlightFee = true) => {
  const totalCharge = roundNumber(amount);
  let preflightFees = dismissPreFlightFee ? 0 : roundNumber(amount * 0.005);
  if (preflightFees < 0.5) {
    preflightFees = 0;
  }
  const stripeConnectFees = roundNumber(
    totalCharge <= 0 ? 0 : totalCharge * 0.0025 + 0.25
  );
  // const stripeConnectFees = 0;

  if (clubPaysFees) {
    const stripeAchFees = Math.min(
      Math.round(totalCharge * 0.008 * 100) / 100,
      5
    );
    const stripeCCFees = 0;
    const totalStripeFees = stripeCCFees + stripeConnectFees + stripeAchFees;
    const applicationFee = totalStripeFees + preflightFees;

    const costToPilot = totalCharge;
    const clubRevenue = roundNumber(totalCharge - applicationFee);
    const chargeToClub = 0;
    const chargeToPilot = costToPilot;

    return {
      totalCharge,
      preflightFees,
      stripeConnectFees,
      stripeCCFees,
      stripeAchFees,
      totalStripeFees,
      applicationFee,
      costToPilot,
      clubRevenue,
      chargeToClub,
      chargeToPilot,
    };
  }

  const subTotal = totalCharge + preflightFees + stripeConnectFees;
  const stripeAchFees = Math.min(Math.round(subTotal * 0.008 * 100) / 100, 5);
  const stripeCCFees = 0;
  const totalStripeFees = stripeCCFees + stripeConnectFees + stripeAchFees;
  const applicationFee = totalStripeFees + preflightFees;

  const costToPilot = totalCharge + applicationFee;
  const clubRevenue = totalCharge;
  const chargeToClub = 0;
  const chargeToPilot = Math.max(costToPilot, applicationFee);

  return {
    totalCharge,
    preflightFees,
    stripeConnectFees,
    stripeCCFees,
    stripeAchFees,
    totalStripeFees,
    applicationFee,
    costToPilot,
    clubRevenue,
    chargeToClub,
    chargeToPilot,
  };
};

function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.debug("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

function getProfileImageURL({ user }) {
  if (!user) return "";
  if (user.photoURL) return user.photoURL;

  const emailHash = user.email ? md5(user.email) : "";
  return `https://www.gravatar.com/avatar/${emailHash}?d=mp`;
}

const getYearsSelectData = (startYear = 1900) => {
  const endYear = new Date().getFullYear();
  const totalYears = endYear - startYear + 1;
  return Array.from(new Array(totalYears), (val, index) => endYear - index).map(
    (x) => ({
      value: x,
      label: x,
    })
  );
};

export {
  formatCurrency,
  isDev,
  useWhyDidYouUpdate,
  toTitleCase,
  getBrandIcons,
  convertStringToCamelCase,
  calculateFees,
  calculateAchFees,
  stringToPastelColor,
  getProfileImageURL,
  getYearsSelectData,
};
