import { IPermission } from "./permission.interface";

export enum simulatorRoleCategory {
  SIMULATORS = "SIMULATORS",
}

export enum simulatorPermissionTypes {
  ADD_SIMULATOR = "ADD_SIMULATOR",
  EDIT_SIMULATOR = "EDIT_SIMULATOR",
  VIEW_SIMULATORS = "VIEW_SIMULATORS",
  BOOK_SIMULATOR = "BOOK_SIMULATOR",
  VIEW_SIMULATOR_DETAILS = "VIEW_SIMULATOR_DETAILS",
  DELETE_SIMULATOR = "DELETE_SIMULATOR",
  DISABLE_SIMULATOR = "DISABLE_SIMULATOR",
  ADD_SIMULATOR_SQUAWKS = "ADD_SIMULATOR_SQUAWKS",
}

export const simulatorPermissions: IPermission[] = [
  {
    group: simulatorRoleCategory.SIMULATORS,
    label: "Simulators",
    tooltipGroup: "",
    permissions: {
      [simulatorPermissionTypes.ADD_SIMULATOR]: {
        label: "Add Simulators",
        tooltip:
          "Gives the user the ability to add new simulators to the system",
      },
      [simulatorPermissionTypes.VIEW_SIMULATORS]: {
        label: "View All Simulators",
        tooltip:
          "Disabling this permission will hide the Simulators menu option from Resources",
      },
      [simulatorPermissionTypes.VIEW_SIMULATOR_DETAILS]: {
        label: "View Simulator Details",
        tooltip:
          "Toggle if the user can view the details of an simulators from any page",
      },
      [simulatorPermissionTypes.BOOK_SIMULATOR]: {
        label: "Book Simulators",
        tooltip: "",
      },
      [simulatorPermissionTypes.EDIT_SIMULATOR]: {
        label: "Edit Simulator",
        tooltip: "",
      },
      [simulatorPermissionTypes.DELETE_SIMULATOR]: {
        label: "Delete Simulator",
        tooltip: "",
      },
      [simulatorPermissionTypes.DISABLE_SIMULATOR]: {
        label: "Disable Simulator",
        tooltip: "",
      },
      [simulatorPermissionTypes.ADD_SIMULATOR_SQUAWKS]: {
        label: "Add Simulator Squawks",
        tooltip: "",
      },
    },
  },
];
