import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import PropTypes from "prop-types";
import SoftSelect from "src/components/SoftSelect";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { getFunctions, httpsCallable } from "firebase/functions";
import { calculateFees } from "src/features/utils";
import { useClubs } from "src/features/club/ClubProvider";

const schema = yup
  .object({
    type: yup.object(),
    hobbsStart: yup.number().when("type.value", {
      is: "hobbstach",
      then: (elementSchema) =>
        elementSchema
          .positive("New value must be a positive number")
          .required("Hobbs Start is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    hobbsEnd: yup.number().when("type.value", {
      is: "hobbstach",
      then: (elementSchema) =>
        elementSchema
          .positive("New value must be a positive number")
          .required("Hobbs End is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    tachStart: yup.number().when("type.value", {
      is: "hobbstach",
      then: (elementSchema) =>
        elementSchema.positive("New value must be a positive number"),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    tachEnd: yup.number().when("type.value", {
      is: "hobbstach",
      then: (elementSchema) =>
        elementSchema.positive("New value must be a positive number"),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    note: yup.string(),
  })
  .required();

export function AdjustInvoiceModal({ handleClose, transaction }) {
  const { selectedLocation, clubUsers } = useClubs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalAmount, setTotalAmount] = useState(false);
  const adjustmentOptions = [
    {
      label: "Hobbs/Tach Adjustment",
      value: "hobbstach",
    },
    // {
    //   label: "Member Adjustment",
    //   value: "member",
    // },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      type: adjustmentOptions[0],
      hobbsStart: transaction?.bookingData?.previousHobbs,
      hobbsEnd: transaction?.bookingData?.hobbs,
      tachStart: transaction?.bookingData?.previousTach1,
      tachEnd: transaction?.bookingData?.tach1,
      member: {
        value: transaction?.pilot?.id,
        label: transaction?.pilot?.displayName,
      },
    },
    resolver: yupResolver(schema),
  });

  const adjustmentType = watch("type");
  const hobbsStart = watch("hobbsStart");
  const hobbsEnd = watch("hobbsEnd");
  const tachStart = watch("tachStart");
  const tachEnd = watch("tachEnd");

  useEffect(() => {
    const totalHobbsHours = hobbsEnd - hobbsStart;
    const totalTach1Hours = tachEnd - tachStart;

    const hourlyRate =
      transaction.selectedRate?.hourlyRate ||
      transaction.aircraft?.rates?.standard ||
      transaction.aircraft?.hourlyRate ||
      0;

    let totalFlightCost =
      transaction.aircraft?.billFrom === "hobbs"
        ? totalHobbsHours * hourlyRate
        : totalTach1Hours * hourlyRate;

    if (
      transaction?.bookingData?.instructionHours &&
      transaction?.bookingData?.instructionHours > 0 &&
      transaction?.instructor?.hourlyRate
    ) {
      totalFlightCost +=
        transaction.bookingData.instructionHours *
        transaction.instructor.hourlyRate;
    }

    if (
      transaction?.bookingData?.instructionHoursGround &&
      transaction?.bookingData?.instructionHoursGround > 0 &&
      transaction?.instructor?.hourlyRateGround
    ) {
      totalFlightCost +=
        transaction.bookingData.instructionHoursGround *
        transaction.instructor.hourlyRateGround;
    }

    const calculatedFees = calculateFees(
      totalFlightCost,
      transaction.fuelCost,
      transaction.preHeatCost,
      selectedLocation.preferences?.clubPaysFees ||
        selectedLocation.preferences?.clubPaysFeesOnAccount,
      false,
      ""
    );
    setTotalAmount(calculatedFees.chargeToPilot);
  }, [hobbsStart, hobbsEnd, tachStart, tachEnd]);

  const submitAdjustment = (data) => {
    setIsSubmitting(true);

    data.totalAmount = totalAmount;

    const functions = getFunctions();
    const adjustTransaction = httpsCallable(functions, "adjustTransaction");
    adjustTransaction({
      clubId: transaction.club.id,
      transactionId: transaction.id,
      hobbsStart: data.hobbsStart,
      hobbsEnd: data.hobbsEnd,
      tachStart: data.tachStart,
      tachEnd: data.tachEnd,
      member: data.member.value,
      type: data.type.value,
      note: data.note,
    }).then(() => {
      setIsSubmitting(false);
      handleClose(true);
    });
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(submitAdjustment)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Adjust Invoice
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Adjustment Type
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={adjustmentOptions}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.type?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.type.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {adjustmentType.value === "member" && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Member
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox flexGrow={1} ml={2}>
                    <Controller
                      control={control}
                      name="member"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={Array.from(clubUsers.values()).map(
                            (user) => ({
                              label: user.displayName,
                              value: user.uid,
                            })
                          )}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.member?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.member.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
              {adjustmentType.value === "hobbstach" && (
                <>
                  <Grid item xs={6}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Hobbs Start
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="hobbsStart"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            value={value}
                            ref={ref}
                            type="number"
                            bold
                            error={value < 0}
                            size="small"
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.hobbsStart?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.hobbsStart.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Hobbs End
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="hobbsEnd"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            value={value}
                            ref={ref}
                            type="number"
                            bold
                            error={value < 0}
                            size="small"
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.hobbsEnd?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.hobbsEnd.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Tach Start
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="tachStart"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            value={value}
                            ref={ref}
                            type="number"
                            bold
                            error={value < 0}
                            size="small"
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.tachStart?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.tachStart.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Tach End
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <Controller
                        control={control}
                        name="tachEnd"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftInput
                            value={value}
                            ref={ref}
                            type="number"
                            bold
                            error={value < 0}
                            size="small"
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.tachEnd?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.tachEnd.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Internal Note
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <SoftInput
                    placeholder="Write your comment"
                    multiline
                    {...register("note", {
                      required: true,
                    })}
                    rows={5}
                  />
                  {errors?.note?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.note.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={4}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton disabled={isSubmitting} type="submit" color="error">
              Adjust Transaction
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AdjustInvoiceModal.propTypes = {
  handleClose: PropTypes.func,
  transaction: PropTypes.object,
};
