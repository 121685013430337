import { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Document from "src/components/Icons/Document";
import Settings from "src/components/Icons/Settings";
import AppBar from "@mui/material/AppBar";
import { SupervisorAccount } from "@mui/icons-material";
import SoftTypography from "src/components/SoftTypography";
import TabPanel from "src/components/TabPanel";
import { getInstructorDocumentsCollection } from "src/features/instructor/collections";
import { getUserDocumentsCollection } from "src/features/user/collections";
import { useClubs } from "src/features/club/ClubProvider";
import InformationBite from "src/components/InformationBite";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import DocumentsList from "src/components/DocumentsList";
import Modal from "@mui/material/Modal";
import { UploadDocumentModal } from "src/modals/UploadDocument";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import UserCard from "src/components/UserCard";
import InstructorPreferences from "src/pages/resources/instructors/InstructorPreferences";
import Swal from "sweetalert2";
import { doc, updateDoc } from "firebase/firestore";
import * as toastr from "toastr";

const iconAnim = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const StyledSupervisorAccountIcon = styled(SupervisorAccount)`
  animation: ${iconAnim} 4s infinite;
`;

const documentTypesClub = [{ label: "Other", value: "" }];
const documentTypesUser = [
  { label: "Pilot Certificate", value: "certificate" },
  { label: "Other", value: "" },
];

function InstructorInfo({ instructor }) {
  const [tabValue, setTabValue] = useState(0);
  const { selectedClubId, selectedLocationId } = useClubs();
  const { addData } = entityCrudUtils();

  const uploadPathClub = `clubs/${selectedClubId}/locations/${selectedLocationId}/instructors`;
  const uploadPathUser = `users/${instructor?.id}/documents`;

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [openAddClubDocument, setOpenAddClubDocument] = useState(false);
  const handleOpenAddClubDocument = () => setOpenAddClubDocument(true);
  const handleCloseAddClubDocument = async (documentsAdded) => {
    if (documentsAdded && documentsAdded.length > 0) {
      await Promise.all(
        documentsAdded.map(async (document) => {
          await addData(
            getInstructorDocumentsCollection(
              selectedClubId,
              selectedLocationId,
              instructor?.id
            ),
            document
          );
        })
      );
    }
    setOpenAddClubDocument(false);
  };

  const [openAddUserDocument, setOpenAddUserDocument] = useState(false);
  const handleOpenAddUserDocument = () => setOpenAddUserDocument(true);
  const handleCloseAddUserDocument = async (documentsAdded) => {
    if (documentsAdded && documentsAdded.length > 0) {
      await Promise.all(
        documentsAdded.map(async (document) => {
          await addData(getUserDocumentsCollection(instructor?.id), document);
        })
      );
    }
    setOpenAddUserDocument(false);
  };

  const deleteDocument = async (document) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(
            doc(getUserDocumentsCollection(instructor?.id), document.id),
            { deleted: true }
          );
          toastr.success("Document deleted successfully");
        }
      });
  };
  const deleteDocumentInternal = async (document) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(
            doc(
              getInstructorDocumentsCollection(
                selectedClubId,
                selectedLocationId,
                instructor?.id
              ),
              document.id
            ),
            { deleted: true }
          );
          toastr.success("Document deleted successfully");
        }
      });
  };

  if (!instructor)
    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexGrow="1"
        sx={{ height: "100%" }}
      >
        <StyledSupervisorAccountIcon
          color="dark"
          sx={{ width: 130, height: 130 }}
        />
        <SoftTypography>please select an instructor</SoftTypography>
      </SoftBox>
    );

  return (
    <SoftBox sx={{ flexGrow: 1 }} variant="contained" padding={1}>
      <Grid container columns={{ xs: 12 }}>
        <Grid item xs={4}>
          <UserCard user={instructor} />
        </Grid>
        <Grid item xs={8} padding={1}>
          <AppBar position="static">
            <Tabs
              orientation="horizontal"
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                backgroundColor: "transparent",
              }}
            >
              <Tab label="Settings" icon={<Settings />} />
              <Tab label="User Details" icon={<Document />} />
            </Tabs>
          </AppBar>

          <TabPanel value={tabValue} index={0}>
            <SoftBox>
              <InstructorPreferences instructor={instructor} />
            </SoftBox>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
              <Grid item xs={12}>
                <InformationBite
                  label="Description"
                  value="No description set"
                />
              </Grid>
            </Grid>
          </TabPanel>
          {/* <TabPanel value={tabValue} index={2}>
            
          </TabPanel> */}
        </Grid>
        <Grid item xs={12} marginTop={2} borderRadius="sm">
          <DocumentsList
            title="Internal Documents (Private)"
            documentsCollection={getInstructorDocumentsCollection(
              selectedClubId,
              selectedLocationId,
              instructor?.id
            )}
            openUploadModal={handleOpenAddClubDocument}
            documentTypes={documentTypesClub}
            deleteDocument={deleteDocumentInternal}
          />
        </Grid>
        <Grid item xs={12} marginTop={2} borderRadius="sm">
          <DocumentsList
            title="User Documents"
            documentsCollection={getUserDocumentsCollection(instructor?.id)}
            openUploadModal={handleOpenAddUserDocument}
            documentTypes={documentTypesUser}
            deleteDocument={deleteDocument}
          />
        </Grid>
      </Grid>
      <Modal
        open={openAddClubDocument}
        onClose={handleCloseAddClubDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <UploadDocumentModal
          handleClose={handleCloseAddClubDocument}
          documentTypes={documentTypesClub}
          storagePath={uploadPathClub}
        />
      </Modal>
      <Modal
        open={openAddUserDocument}
        onClose={handleCloseAddUserDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <UploadDocumentModal
          handleClose={handleCloseAddUserDocument}
          documentTypes={documentTypesUser}
          storagePath={uploadPathUser}
        />
      </Modal>
    </SoftBox>
  );
}

InstructorInfo.propTypes = {
  instructor: PropTypes.object,
};

export default InstructorInfo;
