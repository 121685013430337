import { Auth } from "firebase/auth";
import { useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { ApiWrapper } from "src/services/fetchClient";

interface IUserToken {
  auth: Auth;
  children: React.ReactNode;
}
function UserToken({ children, auth }: IUserToken) {
  const [user, loading] = useIdToken(auth);

  useEffect(() => {
    ApiWrapper.registerSessionTokenFactory(async () => {
      if (user) return await user.getIdToken();
      return "";
    });
  }, [user]);
  if (loading) return null;

  return <>{children}</>;
}
export default UserToken;
