import {
  Accordion,
  AccordionSummary,
  Card,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { useMemo, useState } from "react";
import SoftBox from "src/components/SoftBox";
import DataTable from "src/components/Tables/DataTable";
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import Footer from "src/components/Footer";
import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import { format } from "date-fns";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { query, where } from "firebase/firestore";
import SoftTypography from "src/components/SoftTypography";
import { toTitleCase } from "src/features/utils";
import { Check, Close, ExpandMore } from "@mui/icons-material";
import { useCollection } from "react-firebase-hooks/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";

const columns = [
  {
    Header: "Student",
    accessor: "member.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Course",
    accessor: "course.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "start date",
    accessor: "startDate",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PP")} /> : null,
  },
  {
    Header: "end date",
    accessor: "completionDate",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PP")} /> : null,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? toTitleCase(value.replace("_", " ")) : "No status"}
      />
    ),
  },
  {
    Header: "next booking",
    accessor: "nextBooking",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value, "PP")} /> : null,
  },
];
const columnsNotEnrolled = [
  {
    Header: "Student",
    accessor: "displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "next booking",
    accessor: "nextBooking",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value, "PP")} /> : null,
  },
];

export default function EnrolledStudentReport() {
  const { selectedClubId, selectedLocationId, instructors } = useClubs();
  const { getClubEnrollmentsData } = useLms();
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [instructorsMap, setInstructorsMap] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [dateNow] = useState(new Date());

  const getEnrolledCourses = async () => {
    const enrollments = await getClubEnrollmentsData(selectedLocationId, null, [
      where("status", "!=", "COMPLETED"),
    ]);

    const enrolledCoursesData = enrollments.flatMap(
      (enrollment) => enrollment.enrolledCourses
    );

    setEnrolledCourses(enrolledCoursesData);
    setIsLoading(false);
  };

  useMemo(() => {
    const instructorsNewMap = new Map();

    instructors.forEach((instructor) => {
      if (instructor.id)
        instructorsNewMap.set(instructor.uid, {
          ...instructor,
          students: [],
          notEnrolled: [],
        });
    });

    enrolledCourses.forEach((course) => {
      const instructor = instructorsNewMap.get(course.instructor?.value);
      if (instructor) {
        instructor.students.push(course);
        instructorsNewMap.set(instructor.id, instructor);
      }
    });
    setInstructorsMap(instructorsNewMap);
  }, [enrolledCourses, instructors]);

  const [bookingsSnapshot, loadingBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", false),
      where("end", ">=", dateNow)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useMemo(() => {
    if (bookingsSnapshot?.docs?.length > 0 && !loadingBookings) {
      const bookings = bookingsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      bookings.forEach((booking) => {
        let hasBooking = false;
        const instructor = instructorsMap.get(
          booking.extendedProps?.instructor?.value
        );
        if (instructor) {
          instructor.students.forEach((student) => {
            if (
              student.member?.value === booking.extendedProps?.member?.value ||
              student.member?.value === booking.extendedProps?.pilot?.value
            ) {
              if (student.nextBooking) {
                if (student.nextBooking > booking.start.toDate()) {
                  student.nextBooking = booking.start.toDate();
                }
              } else {
                student.nextBooking = booking.start.toDate();
              }
              hasBooking = true;
            }
          });
          if (!hasBooking) {
            const pilot =
              booking.extendedProps?.pilot || booking.extendedProps.member;
            instructor.notEnrolled.push({
              displayName: pilot?.label,
              id: pilot?.value,
              nextBooking: booking.start.toDate(),
            });
          }

          instructorsMap.set(instructor.id, instructor);
        }
      });
    }
  }, [loadingBookings, bookingsSnapshot?.docs]);

  useMemo(() => {
    getEnrolledCourses();
  }, [selectedLocationId]);

  const enrolledCount = useMemo(() => {
    return Array.from(instructorsMap.values()).reduce(
      (acc, instructor) => acc + instructor.students.length,
      0
    );
  }, [instructorsMap]);

  const notEnrolledCount = useMemo(() => {
    return Array.from(instructorsMap.values()).reduce(
      (acc, instructor) => acc + instructor.notEnrolled.length,
      0
    );
  }, [instructorsMap]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <SoftBox display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </SoftBox>
      ) : (
        <SoftBox>
          <SoftBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Enrolled",
                    fontWeight: "medium",
                  }}
                  count={`${enrolledCount} Students`}
                  icon={{
                    color: "success",
                    component: <Check />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Not Enrolled",
                    fontWeight: "medium",
                  }}
                  count={`${notEnrolledCount} Pilots`}
                  icon={{
                    color: "error",
                    component: <Close />,
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Card>
              {Array.from(instructorsMap.values())
                .sort((a, b) => b.students.length - a.students.length)
                .map((instructor) => {
                  return (
                    <>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <SoftBox>
                            <SoftTypography variant="h6" fontWeight="medium">
                              {instructor.displayName}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text">
                              {instructor.email}
                            </SoftTypography>
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {instructor.students.length} Students
                            </SoftTypography>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9}>
                          <Accordion
                            elevation={0}
                            sx={({ palette: { light } }) => ({
                              border: 1,
                              borderColor: light.main,
                            })}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              sx={{
                                background: "#E9ecef",
                              }}
                            >
                              <SoftBox>
                                <SoftTypography
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  Enrolled ({instructor.students.length})
                                </SoftTypography>
                              </SoftBox>
                            </AccordionSummary>
                            {instructor.students.length > 0 && (
                              <DataTable
                                table={{ columns, rows: instructor.students }}
                                entriesPerPage={false}
                                pagination={false}
                                showTotalEntries={false}
                              />
                            )}
                          </Accordion>

                          <Accordion
                            elevation={0}
                            sx={({ palette: { light } }) => ({
                              border: 1,
                              borderColor: light.main,
                            })}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              sx={{
                                background: "#E9ecef",
                              }}
                            >
                              <SoftBox>
                                <SoftTypography
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  Not Enrolled ({instructor.notEnrolled?.length}
                                  )
                                </SoftTypography>
                              </SoftBox>
                            </AccordionSummary>
                            {instructor.notEnrolled.length > 0 && (
                              <DataTable
                                table={{
                                  columns: columnsNotEnrolled,
                                  rows: instructor.notEnrolled,
                                }}
                                entriesPerPage={false}
                                pagination={false}
                                showTotalEntries={false}
                              />
                            )}
                          </Accordion>
                        </Grid>
                      </Grid>
                      {instructorsMap.size > 1 && <Divider />}
                    </>
                  );
                })}
            </Card>
          </SoftBox>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
