import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  setPersistence,
  updatePassword,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithCustomToken,
} from "firebase/auth";
import {
  query,
  getDocs,
  where,
  addDoc,
  setDoc,
  doc,
  getDoc,
  collectionGroup,
} from "firebase/firestore";
import { getDatabase, goOffline } from "firebase/database";
import { db } from "src/features/firebase/firestore/utils";
import { firebaseApp } from "src/features/firebase/index";
import {
  getUsersCollection,
  getUserPermissionsCollection,
} from "src/features/user/collections";
import { getLocationsCollectionGroup } from "src/features/location/collections";

const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

const setLocalStoragePersistence = async () => {
  await setPersistence(auth, browserLocalPersistence);
};

const setSessionPersistence = async () => {
  await setPersistence(auth, browserSessionPersistence);
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const { user } = res;
    const q = query(getUsersCollection(), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(getUsersCollection(), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const getUserInfo = async (user) => {
  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
};

const getClubByLocationId = async (locationId) => {
  const q = query(
    collectionGroup(db, "locations"),
    where("id", "==", locationId)
  );
  const querySnapshot = await getDocs(q);
  const clubs = [];
  querySnapshot.forEach(async (location) => {
    const club = await getDoc(location.ref.parent.parent);
    clubs.push(club.data());
  });
  return clubs;
};

const loginWithCustomToken = async (token) => {
  try {
    return await signInWithCustomToken(auth, token);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  password,
  isUserInfoPending
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    await setDoc(doc(getUsersCollection(), user.uid), {
      uid: user.uid,
      firstName,
      lastName,
      displayName: `${firstName} ${lastName}`,
      authProvider: "local",
      email,
      isUserInfoPending,
      createdAt: new Date(),
    });
    return await getDoc(doc(getUsersCollection(), user.uid));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const changePassword = async (email, password, newPassword) => {
  // try {
  const user = await signInWithEmailAndPassword(auth, email, password);
  await updatePassword(user.user, newPassword);
  // } catch (err) {
  //   switch (err.code) {
  //     case 'auth/invalid-password':
  //       throw TypeError('Password must be at least 6 characters long');

  //     case 'auth/wrong-password':
  //       throw TypeError('Current password is incorrect');

  //     default:
  //       throw TypeError('An error occurred, please try again');
  //   }
  // }
  return user;
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => {
  await goOffline(getDatabase());
  await signOut(auth);
};

const finaliseUserInfoAdding = async (userId, referredLocationId) => {
  if (referredLocationId) {
    const locations = await getDocs(
      query(
        getLocationsCollectionGroup(),
        where("id", "==", referredLocationId)
      )
    );
    locations.forEach(async (location) => {
      const clubRef = await getDoc(location.ref.parent.parent);
      if (location.data()?.preferences?.allowPublicRegistration) {
        await setDoc(doc(getUserPermissionsCollection(userId), location.id), {
          addedAt: new Date(),
          clubId: clubRef.id,
          locationId: location.id,
          hide: false,
          invite: true,
          userType: "member",
          approved: false,
        });
      }
    });
  }
  await setDoc(
    doc(getUsersCollection(), userId),
    {
      isUserInfoPending: false,
    },
    { merge: true }
  );
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  loginWithCustomToken,
  registerWithEmailAndPassword,
  sendPasswordReset,
  changePassword,
  logout,
  getUserInfo,
  setSessionPersistence,
  setLocalStoragePersistence,
  getClubByLocationId,
  finaliseUserInfoAdding,
};
