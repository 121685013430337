import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import FlightIcon from "@mui/icons-material/Flight";

import SoftInput from "src/components/SoftInput";

function SimulatorDataForm({ aircraft, onHobbsChanged }) {
  const onTotalTimeChanged = (value) => {
    onHobbsChanged(
      aircraft?.hobbs || 0,
      (aircraft?.hobbs || 0) + parseFloat(value)
    );
  };

  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <FlightIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="dark"
              fontSize="large"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Simulator
            </SoftTypography>
            <SoftTypography
              variant="caption"
              color="text"
              fontSize="1.3rem"
              fontWeight="bold"
            >
              {aircraft?.aircraft_description?.tail_number ??
                aircraft?.tailNumber}
            </SoftTypography>
            <SoftTypography variant="caption" fontSize="0.8rem" color="text">
              {aircraft.displayName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8} mt={{ xs: 3, sm: 0 }}>
        <SoftBox
          display="flex"
          alignItems="center"
          py={2}
          borderRadius="md"
          bgColor="light"
        >
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent="center"
            direction="column"
          >
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent="center"
              >
                <>
                  <Grid item xs={5} sm={5}>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      height="100%"
                      pr={3}
                    >
                      <SoftBox display="flex" flexDirection="column">
                        <SoftTypography
                          variant="button"
                          color="dark"
                          fontWeight="medium"
                        >
                          Total Time (hrs):
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" flexDirection="column">
                        <SoftInput
                          type="number"
                          bold
                          size="small"
                          onWheel={(event) => event.target.blur()}
                          sx={{ maxWidth: "7rem" }}
                          onChange={(event) =>
                            onTotalTimeChanged(event.target.value)
                          }
                        />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
SimulatorDataForm.propTypes = {
  aircraft: PropTypes.object,
  totalHobbs: PropTypes.number,
  totalTach: PropTypes.number,
  onHobbsChanged: PropTypes.func,
  onTachChanged: PropTypes.func,
};

export default SimulatorDataForm;
