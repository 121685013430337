import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import PersonIcon from "@mui/icons-material/Person";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import { useClubs } from "src/features/club/ClubProvider";

function BookingData({ booking, mb, userSelected }) {
  const { selectedLocation } = useClubs();

  return (
    <Grid
      container
      spacing={1}
      display="flex"
      justifyContent="center"
      mb={mb || 0}
    >
      {selectedLocation.preferences?.calendar?.hideMembersNames ? null : (
        <Grid item xs={12} sm={4}>
          <SoftBox>
            <SoftBox display="flex" alignItems="center">
              <SoftBox
                display="grid"
                alignItems="center"
                justifyContent="center"
                borderRadius="lg"
                width="2rem"
                height="2rem"
                mr={2}
                sx={{ cursor: userSelected ? "pointer" : "default" }}
                onClick={() =>
                  userSelected
                    ? userSelected(booking?.extendedProps?.pilot?.value)
                    : null
                }
              >
                <PersonIcon
                  sx={{
                    display: "grid",
                    placeItems: "center",
                  }}
                  color="dark"
                  fontSize="medium"
                />
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                sx={{ cursor: userSelected ? "pointer" : "default" }}
                onClick={() =>
                  userSelected
                    ? userSelected(booking?.extendedProps?.pilot?.value)
                    : null
                }
              >
                <SoftTypography
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  gutterBottom
                >
                  {booking.extendedProps.pilot2?.value ? "Member 1" : "Member"}
                </SoftTypography>
                <SoftTypography variant="caption" color="text">
                  {booking.extendedProps.pilot?.label}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              alignItems="center"
              justifyContent="center"
              width="2rem"
              height="2rem"
              mr={2}
              sx={{ cursor: userSelected ? "pointer" : "default" }}
              onClick={() =>
                userSelected
                  ? userSelected(booking?.extendedProps?.instructor?.value)
                  : null
              }
            >
              <SupervisorAccountIcon
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                color="dark"
                fontSize="medium"
              />
            </SoftBox>
            <SoftBox
              display="flex"
              flexDirection="column"
              sx={{ cursor: userSelected ? "pointer" : "default" }}
              onClick={() =>
                userSelected
                  ? userSelected(booking?.extendedProps?.instructor?.value)
                  : null
              }
            >
              <SoftTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                gutterBottom
              >
                {booking.extendedProps.pilot2?.value
                  ? "Member 2"
                  : "Instructor"}
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {booking.extendedProps.pilot2?.value
                  ? booking.extendedProps.pilot2?.label
                  : booking.extendedProps.instructor?.label}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              alignItems="center"
              justifyContent="center"
              width="2rem"
              height="2rem"
              mr={2}
            >
              <AirplaneTicketIcon
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                color="dark"
                fontSize="medium"
              />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                gutterBottom
              >
                Flight Rules
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                VFR
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
BookingData.propTypes = {
  booking: PropTypes.object,
  mb: PropTypes.number,
  userSelected: PropTypes.func,
};

export default BookingData;
